import React from 'react';
import elevateBrand from "../../assets/ElevateBrand.svg";
import demonstrateExpertise from "../../assets/DemonstrateExpertise.svg";
import showcaseBusiness from "../../assets/ShowcaseBusiness.svg";
import connectWithLeaders from "../../assets/ConnectWithIndustry.svg";


function Sponsors() {

    const sponsorInfo = [
        {
            img: elevateBrand,
            title: "Elevate your Brand",
            description: "Establish Consistency and Promote Instant Recognition Through Strategic Branding Initiatives.",
        },
        {
            img: demonstrateExpertise,
            title: "Demonstrate your expertise on the agenda",
            description: "Capture the spotlight! Optimize your impact by strategically scheduling your talk before a networking break, allowing for immediate follow- up discussions with enthusiastic participants.Elevate your influence effortlessly!",
        },
        {
            img: showcaseBusiness,
            title: "Showcase your business & its services",
            description: "Stand out from the crowd! Showcase your unique value proposition and outshine competitors in front of a dynamic audience of active, engaged potential customers.Elevate your brand and capture attention effortlessly.",
        },
        {
            img: connectWithLeaders,
            title: "Connect with Industry Leaders",
            description: "Engage with top decision-makers in our exclusive roundtable workshops, designed for meaningful interactions and lasting connections spending over 8 hours networking with key decision-makers. Seize the opportunity to connect and collaborate like never before!",
        }
    ]
    return (
        <div className="bg-white pb-5">
            <div>
                <div className='grid text-center justify-center py-[1.827vh] px-[2.646vw] '>
                    <p className='mt-20 font-semibold text-48px'>Why sponsor?</p>
                    <p className='font-medium text-16px text-[#727272]'>There are many benefits of becoming our sponsors</p>
                </div>
            </div>
            <div className='grid gap-12 mt-12 xs:mx-10 md:mx-20 md:grid-cols-2'>
                {sponsorInfo.map((info) => {
                    return (
                        <div>
                            <img src={info.img} alt={info.title} className="mb-6" />
                            <p className='mb-2 font-semibold text-24px'>{info.title}</p>
                            <p className='font-medium text-16px text-[#727272]'>{info.description}</p>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default Sponsors