import React, { useState } from 'react';
import greenCheckMark from "../../assets/greenCheckMark.svg";
import RegistrationForm from './RegistrationForm';

function RegisterCard2({ plan, curr, amt, title, priceEarly, priceRegular, lunch, student, }) {
    const [purchaseModal, setPurchaseModal] = useState(false);
    const featuresList = [
        "Full Conference (Day 1 & Day 2)",
        "Live Stream Access",
        "Keynote Sessions",
        "Panel Discussions",
        "Tea/Coffee/Snacks"
    ];

    if (title === "International Delegates") {
        featuresList.push("Networking Lunch")
    }
    const [checkedLunch, setCheckedLunch] = useState(false);
    return (
        <>
            {
                purchaseModal ? (
                    <RegistrationForm setModal={setPurchaseModal} plan={plan} currency={curr} amount={amt} food={checkedLunch} />
                ) : (

                    <div className='grid justify-between border border-solid border-[#DFDFDF] xs:p-4 md:p-8 text-left h-full'>
                        <div className='border-b border-solid border-[#DFDFDF]'>
                            <p className='text-[#231D4F] md:text-30px font-semibold mb-3'>For {title}</p >
                            <p className='mb-2 text-black text-20px'>Regular Price : <span className='font-bold text-brand-green text-30px'>{priceEarly}{student ? "/-" : ""}</span></p>
                            <p className='text-16px text-[#7E7E7E] mb-4'>On-spot Price: {priceRegular} {student ? "/-" : ""}</p>
                        </div >
                        <div className='grid justify-between mt-6 text-left'>
                            <p className='text-[#7E7E7E] text-16px mb-4'>What you get</p>
                            <div>
                                {featuresList.map((feature) => (
                                    <div className='flex gap-1 mb-4 text-16px' key={feature}>
                                        <img src={greenCheckMark} alt="Check Mark" />
                                        <p className='text-[#5C5C5C]'>{feature}</p>
                                    </div>
                                ))}
                            </div>
                            <div className=''>
                                <div className='bg-[#FBFBFB] border border-dotted border-[#C2C2C2] p-4'>
                                    <p className='text-[15px] text-[#5C5C5C]'>Add on</p>
                                    <p className='flex gap-4 font-semibold'><input type="checkbox" checked={checkedLunch} onClick={() => setCheckedLunch(!checkedLunch)} className='checkbox text-[18px]' />Get Networking {student ? "lunch" : "Dinner"} for {lunch} only</p>

                                </div>
                                <div className='mt-[2.5rem] flex justify-center'>
                                    <button className='p-3 xs:w-[70vw] md:w-[29.894vw] bg-brand-green' onClick={() => setPurchaseModal(true)}>
                                        <p className='text-white text-16px'>Register</p>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div >
                )
            }
        </>
    )
}

export default RegisterCard2