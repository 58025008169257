import React from 'react';
import twitter from "../../assets/twitter.svg";
import linkedin from "../../assets/linkedin.svg";
import { useNavigate } from 'react-router-dom';


function Footer() {
    const navigate = useNavigate()
    const handleNavigateToCancellationPolicy = () => {
        navigate("/cancellation-policy")
    }

    const handleNavigateToPrivacyPolicy = () => {
        navigate("/privacy-policy")
    }
    return (
        <div className='flex flex-wrap min-h-[52px] xs:px-1 md:px-20 bg-brand-red w-full text-white xs:justify-center md:justify-between items-center'>
            <div className='flex gap-8 align-middle'>
                <a href="https://www.linkedin.com/company/biosymposiums/" target='_blank' rel='noreferrer'><img src={linkedin} alt="LinkedIn" className='cursor-pointer h-7 w-7' /></a>
                <a href="https://x.com/BioSymposiums?t=cunOk6hP6cbrqoij67p22Q&s=08" target='_blank' rel='noreferrer'><img src={twitter} alt="X formerly Twitter" className='mt-1 cursor-pointer h-7 w-7' /></a>

            </div>
            <dir className='flex items-center gap-x-1'>
                <p className='text-20px'>©</p>
                <p className='mt-1'> 2024 - BioSymposiums</p>
            </dir>
            <div className='flex gap-4'>
                <span className='my-auto cursor-pointer' onClick={handleNavigateToCancellationPolicy}>Cancellation Policy</span>
                <span className='h-fit text-30px'>⋅</span>
                <span className='my-auto cursor-pointer' onClick={handleNavigateToPrivacyPolicy}>Privacy Policy</span>
            </div>
        </div>
    )
}

export default Footer