import React, { useEffect, useState } from 'react';
import greenCheckMark from "../../assets/greenCheckMark.svg"
import RegistrationForm from './RegistrationForm';

function RegisterCard({ plan, curr, amt, title, subTitle, priceDetails }) {
    const featuresList = [
        "Full Conference (Day 1 & Day 2)",
        "Live Stream Access",
        "Keynote Sessions",
        "Panel Discussions",
        "Networking Lunch",
        "Tea/Coffee/Snacks"
    ];
    //purchase modal open
    const [purchaseModal, setPurchaseModal] = useState(false);
    // Simulate fetching pricing data from the server
    const [pricingData, setPricingData] = useState(priceDetails);
    // State to store which option is selected
    const [selectedOption, setSelectedOption] = useState(null);
    const [checkedLunch, setCheckedLunch] = useState(false);

    // Handle selection changes
    const handleSelectionChange = (key) => {
        setSelectedOption(key);
    };
    const purchaseHanlder = () => {
        if (selectedOption === null) setSelectedOption("delegate");
        setPurchaseModal(true)
    }
    return (
        <>
            {
                purchaseModal ? (
                    <RegistrationForm setModal={setPurchaseModal} plan={plan} currency={curr} amount={pricingData[selectedOption]?.price} food={checkedLunch} />

                ) : (


                    <div className='w-[90vw] md:w-[73.545vw] mx-auto border border-solid border-[#DFDFDF] p-8'>
                        <div>
                            <div className='pt-2 pb-1 text-left'><span className='text-left text-[#231D4F] md:text-30px font-semibold'>{title} - </span><span className='md:text-22px text-[#7E7E7E]'>{subTitle}</span></div>
                            <div className='grid md:grid-cols-2 md:gap-8'>
                                <div className='border border-solid border-[#DFDFDF] justify-start items-start xs:w-[100%] mt-5'>
                                    <table>
                                        <thead className='p-3'>
                                            <tr className='p-3 md:w-full'>
                                                <th className='xs:text-14px md:text-16px p-3 bg-[#ECECEC] w-[35%] text-brand-green'>Type</th>
                                                <th className='xs:text-14px md:text-16px p-3 bg-[#ECECEC] w-[40%] text-brand-green'>Regular Price</th>
                                                <th className='xs:text-14px md:text-16px p-3 bg-[#ECECEC] w-[40%] text-brand-green'>On-spot Price</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {Object.entries(pricingData).map(([key, { type, price, price2 }]) => (
                                                <tr key={key} className='border-b border-solid border-[#DFDFDF]'>
                                                    <td className='flex gap-2 p-6 font-semibold xs:text-14px md:text-16px text-start'>
                                                        <input
                                                            type="radio"
                                                            name="pricingOption"
                                                            defaultChecked={selectedOption === key}
                                                            onChange={() => handleSelectionChange(key)}
                                                            className='radio'
                                                        />
                                                        {type}
                                                    </td>
                                                    <td className='p-2 font-semibold xs:text-14px md:text-16px'>Rs. {price.toLocaleString()}/-</td>
                                                    <td className='p-2 font-semibold xs:text-14px md:text-16px'>Rs. {price2.toLocaleString()}/-</td>
                                                </tr>
                                            ))}
                                            <tr className=''>
                                                <td className='flex gap-2 p-6 font-semibold xs:text-14px md:text-16px text-start'><input type="checkbox" defaultChecked={checkedLunch} onClick={() => setCheckedLunch(!checkedLunch)} className='checkbox' />Networking Dinner</td>
                                                <td className='p-2 font-semibold xs:text-14px md:text-16px'>Rs. 5000/-</td>
                                                <td className='p-2 font-semibold xs:text-14px md:text-16px'>Rs. 5000/-</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className='mt-auto text-left'>
                                    <p className='text-[#7E7E7E] text-16px mb-4'>What you get</p>
                                    <div>
                                        {featuresList.map((feature) => (
                                            <div className='flex gap-1 mb-4 text-16px' key={feature}>
                                                <img src={greenCheckMark} alt="Check Mark" />
                                                <p className='text-[#5C5C5C]'>{feature}</p>
                                            </div>
                                        ))}
                                    </div>
                                    <div className='mt-[5.2rem]'>
                                        <button className='p-3 xs:w-[70vw] md:w-[29.894vw] bg-brand-green' onClick={() => purchaseHanlder()}>
                                            <p className='text-white text-16px'>Register</p>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div >
                )
            }
        </>
    )
}

export default RegisterCard;
