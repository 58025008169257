import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logo from "../../assets/BioSymposiums 1.svg";
import registerMic from "../../assets/register_mic.svg";

function Navbar({ active, onNavigate }) {
    const [isOpen, setIsOpen] = useState(false); // State to manage the hamburger menu visibility

    return (
        <div className={`sticky flex justify-between items-center py-[1.827vh] px-[2.646vw] transition-all duration-300 z-[10] ${active !== "Home" ? "bg-white top-0 pt-8" : "bg-transparent pt-4 top-3"}`}>
            <Link to="/">
                <img src={logo} alt="BioSymposiums" className='min-h-[32px] h-8 w-[144.48px]' />
            </Link>
            <button className="md:hidden" onClick={() => setIsOpen(!isOpen)}>
                <svg className="w-6 h-6" fill="none" stroke="#F62028" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7"></path></svg>
            </button>
            <div className={`flex-col md:flex-row md:flex gap-4 ${isOpen ? 'xs:absolute xs:top-12 md:relative flex xs:w-[95%] md:w-auto bg-white bg-opacity-70 md:bg-opacity-0 p-2 md:p-0 rounded-md text-black' : 'hidden'} md:items-center`}>
                <ul className="flex flex-col md:flex-row md:gap-14 text-[16px] font-normal">
                    <li className={`${active === "Home" ? "text-brand-red" : active !== "Home" ? "text-black" : "xs:text-black md:text-brand-grey text-opacity-[64%]"} cursor-pointer transition-all duration-300`}
                        onClick={() => onNavigate('Home')}>
                        Home
                    </li>
                    <li className={`${active === "About Us" ? "text-brand-red" : active !== "Home" ? "text-black" : "xs:text-black md:text-brand-grey text-opacity-[64%]"} cursor-pointer transition-all duration-300`}
                        onClick={() => onNavigate('About Us')}>
                        About Us
                    </li>
                    <li className={`${active === "Events" ? "text-brand-red" : active !== "Home" ? "text-black" : "xs:text-black md:text-brand-grey text-opacity-[64%]"} cursor-pointer transition-all duration-300`}
                        onClick={() => onNavigate('Events')}>
                        Events
                    </li>
                    <li className={`${active === "Contact Us" ? "text-brand-red" : active !== "Home" ? "text-black" : "xs:text-black md:text-brand-grey text-opacity-[64%]"} cursor-pointer transition-all duration-300`}
                        onClick={() => onNavigate('Contact Us')}>
                        Contact Us
                    </li>
                </ul>
            </div>
            <div className='hidden md:flex gap-2 items-center border border-solid border-brand-red px-2 py-1 cursor-pointer' onClick={() => onNavigate('Events')}>
                <img src={registerMic} alt="register" className="h-6 w-6" />
                <span className={`font-semibold ${active === "Events" ? "text-brand-red" : active !== "Home" ? "text-black" : "text-white"}`}>Register for Event</span>
            </div>
        </div>
    )
}

export default Navbar;