import React from 'react';
import H2 from '../reusables/H2';
import SubHeading from '../reusables/SubHeading';
import Accordion from '../reusables/Accordion';

function Faq() {
    const faqList = [
        {
            title: "What does BioSymposiums specialise in?",
            description: "BioSymposiums is a dedicated conference organizing company focused on delivering impactful gatherings for biotech and pharma experts in India and internationally. We excel in assembling key stakeholders, thought leaders and innovators to foster connections and exchange knowledge within the industry."
        },
        {
            title: "What services does BioSymposiums offer?",
            description: "At BioSymposiums, we offer a range of services tailored to meet the dynamic needs of our clientele.These include organizing conferences, and exhibitions showcasing the latest breakthroughs in biotech and pharma, networking opportunities to connect and collaborate, and fostering collaborations to drive innovation within the industry."
        }, {
            title: "How can I participate in BioSymposiums' events?",
            description: "Participating in our events is easy! Simply explore our upcoming conferences listed on our website, register for the ones that interest you, and join us for transformative conversations, insightful discussions, and invaluable networking opportunities that contribute to the advancement of biotech and pharma."
        },
        {
            title: "What upcoming conferences can I look forward to from BioSymposiums?",
            description: "You can mark your calendar for our upcoming conferences:\n" +
                "❖ Cancer Immunotherapy & Oncology India in November 2024\n" +
                "❖ Vaccines Summit India in April 2025"
        },
        {
            title: "How can I participate in BioSymposiums' events or become a speaker?",
            description: " If you're interested in participating in our events or becoming a speaker, please reach out to us through the contact information provided on our website. We welcome collaboration and contributions from professionals passionate about advancing the biotech and pharma industry."
        }
    ]
    return (
        <div className='h-full mb-10'>
            <div className='mb-16'>
                <H2>Frequently Asked Questions</H2>
                <SubHeading>You might want to go through the FAQs</SubHeading>

            </div>
            <div className='grid justify-center'>
                {
                    faqList.map((faq) => {
                        return (
                            <div className='mb-8' key={faq.title}>
                                <Accordion title={faq.title} description={faq.description} />
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default Faq