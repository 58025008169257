import React, { useState } from 'react';
import arrow from "../../assets/Arrow.svg"

function EmailForm({ extended = false, color = "green" }) {
    // Form state
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        subject: "",
        message: "",
        companyName: "",
        companyPosition: ""
    });

    // Handle input changes
    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    // Handle form submit
    const handleSubmit = async (e, extended) => {
        e.preventDefault();  // Prevent default form submission behavior

        const emailAPIBody = {
            name: formData.name,
            email: formData.email,
            subject: formData.subject,
            message: formData.message
        };

        if (extended) {
            emailAPIBody.companyName = formData.companyName;
            emailAPIBody.companyPosition = formData.companyPosition;
        }

        try {
            // const response = await fetch("http://localhost:5000/send-query", {
            //     method: "POST",
            //     headers: {
            //         "Content-Type": "application/json"
            //     },
            //     body: JSON.stringify(emailAPIBody)
            // });
            // const resData = await response.json();
            // console.log(resData.message); // Log server response

            // // Clear the form
            // setFormData({
            //     name: "",
            //     email: "",
            //     subject: "",
            //     message: "",
            //     companyName: "",
            //     companyPosition: ""
            // });

            extended ? window.open("https://drive.google.com/file/d/1sJ1CpvZpzpVX4QRhSu__oUW6F2HESJF4/view") : console.log("email sent");
        } catch (error) {
            console.error('Error sending email:', error);
        }
    };

    return (
        <div className='grid gap-y-5'>
            <form onSubmit={(e) => handleSubmit(e, extended)} className='grid gap-y-5'>

                <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    placeholder='Name*'
                    required
                    className="h-[5.482vh] min-h-[50px] border border-solid border-[#E0E0E0] p-5"
                />
                <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    placeholder='Email*'
                    required
                    className="h-[5.482vh] min-h-[50px] border border-solid border-[#E0E0E0] p-5"
                />
                {extended && (
                    <>
                        <input
                            type="text"
                            name="companyName"
                            value={formData.companyName}
                            onChange={handleChange}
                            placeholder='Company Name*'
                            required
                            className="h-[5.482vh] min-h-[50px] border border-solid border-[#E0E0E0] p-5"
                        />
                        <input
                            type="text"
                            name="companyPosition"
                            value={formData.companyPosition}
                            onChange={handleChange}
                            placeholder='Your Designation*'
                            required
                            className="h-[5.482vh] min-h-[50px] border border-solid border-[#E0E0E0] p-5"
                        />
                    </>
                )}
                {!extended &&
                    <>
                        <input
                            type="text"
                            name="subject"
                            value={formData.subject}
                            onChange={handleChange}
                            placeholder='Subject*'
                            required
                            className="h-[5.482vh] min-h-[50px] border border-solid border-[#E0E0E0] p-5"
                        />
                        <textarea
                            name="message"
                            value={formData.message}
                            onChange={handleChange}
                            placeholder='Message*'
                            required
                            className="h-[23.246vh] min-h-[212px] border border-solid border-[#E0E0E0] p-5"
                        />
                    </>
                }

                <button type="submit" className=''><div className={`z-[1] flex gap-4 justify-center items-center text-white font-semibold h-[5.482vh] min-h-[50px] border border-solid ${color === "green" ? "bg-[#638C11]" : "bg-[#F62028]"} ${color === "green" ? "border-[#638C11]" : "border-[#F62028]"}]`}>{extended ? "Download Prospectus" : "Send Message"} <img src={arrow} alt="click here" /></div></button>
            </form>
        </div>
    );
}

export default EmailForm;
