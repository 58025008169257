import React from 'react';
import calendar from "../../assets/calendar.svg";
import locationImg from "../../assets/location.svg";
import LongArrowRight from "../../assets/LongArrowRight.svg";
import { Link } from 'react-router-dom';

function EventCard({ image, title, date, location, link, linkItem, setter }) {

    const handleClick = () => {
        if (linkItem) {

            window.scrollTo({ top: 0, behavior: 'smooth' });
        } else {
            setter(true);
        }
    }
    return (
        <div className='mt-12 p-6 border border-solid border-[#E6E6E6] scale-95'>
            <div>
                <img src={image} alt={title} className='min-h-[240px] min-w-[320px] w-full' />
            </div>
            <div className='p-1'>
                <div>
                    <h3 className='font-semibold text-[24px]'>{title}</h3>
                </div>
                <div className='my-6 flex justify-between text-[#747474] font-medium text-[18px]'>
                    <div className='flex gap-2 justify-center items-center'>
                        <img src={calendar} alt="Date" />
                        <p>{date}</p>
                    </div>
                    <div className='flex gap-2 justify-center items-center'>
                        <img src={locationImg} alt="location" />
                        <p>{location}</p>
                    </div>
                </div>
                <Link to={link} onClick={() => handleClick()}>
                    <div className='flex gap-2 bg-brand-red h-12 w-full text-white items-center justify-center cursor-pointer hover:bg-opacity-95'>
                        <span>{date.includes("2024") ? "Register Now" : "Join Waitlist"}</span>
                        <img src={LongArrowRight} alt="arrow right" className='min-w-[22px] w-[1.255vw]' />
                    </div>
                </Link>
            </div>
        </div>
    )
}

export default EventCard