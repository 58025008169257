import React from 'react';
import CLevelExec from "../../assets/CExecutives.svg";
import DecisionMakers from "../../assets/DecisionMakers.svg";
import DrugDev from "../../assets/DrugDev.svg";
import Manufacturers from "../../assets/Manufacturers.svg";
import productDev from "../../assets/ProductDev.svg";
import principalInvestigators from "../../assets/PrincipalInvestigators.svg";
import seniorScientists from "../../assets/SeniorScientists.svg";
import presidents from "../../assets/Presidents.svg";
import academicians from "../../assets/Academicians.svg";
import rAndD from "../../assets/R&D.svg";
import regulatorySpecialists from "../../assets/Regulatory.svg";
import consultants from "../../assets/Consultants.svg"
import days from "../../assets/Days.svg";
import speakers from "../../assets/Speakers.svg";
import seats from "../../assets/Seats.svg";
import keynoteSessions from '../../assets/KeynoteSessions.svg';
import panelSessions from "../../assets/PanelSessions.svg";


function WhoShouldAttend() {
    return (
        <div className="bg-white min-h-[75vh]">
            <div className='grid text-center justify-center py-[1.827vh] px-[2.646vw] '>
                <p className='mt-20 font-semibold text-48px'>Who should Attend?</p>
                <p className='font-medium text-16px text-[#727272]'>There are the vast list of audiences who could attend this</p>
            </div>
            <div className='xs:mx-5 md:mx-[9.656vw] grid gap-x-14 justify-center gap-5 mt-16 md:grid-cols-2'>
                <div className='grid gap-5 md:gap-12'>
                    <img src={CLevelExec} alt="C Level Executives" />
                    <img src={DecisionMakers} alt="Decision Makers" />
                    <img src={DrugDev} alt="Drug Developers" />
                    <img src={Manufacturers} alt="Manufacturers" />
                    <img src={productDev} alt="Product Developers" />
                    <img src={principalInvestigators} alt="Principal Investigators" />
                </div>
                <div className='grid gap-5 md:gap-12'>
                    <img src={seniorScientists} alt="Senior Scientists" />
                    <img src={presidents} alt="Presidents, VPs & SVPs" />
                    <img src={academicians} alt="Academicians" />
                    <img src={rAndD} alt="Research And Development" />
                    <img src={regulatorySpecialists} alt="Regulatory Specialists" />
                    <img src={consultants} alt="Consultants" />
                </div>
            </div>
            <div className="mt-20 bg-[url('assets/Partnership.svg')] p-[8.114vh]">
                <div className='flex justify-center xs:flex-wrap md:flex-nowrap gap-14'>
                    <img src={days} alt="2 Days" />
                    <img src={speakers} alt="20 Speakers" />
                    <img src={seats} alt="150+ Seats" />
                </div>
                <div className='flex justify-center mt-10 xs:flex-wrap md:flex-nowrap gap-14'>
                    <img src={keynoteSessions} alt="8 Keynote Sessions" />
                    <img src={panelSessions} alt="8 Panel Sessions" />
                </div>
            </div>
        </div>
    )
}

export default WhoShouldAttend