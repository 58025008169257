import React from 'react';
import CIOLogo from "../../assets/CIO2024Logo.svg"
import calendar from "../../assets/calendar.svg";
import locationPin from "../../assets/location.svg";

function About() {
    return (
        <div className='xs:py-10 md:py-20 xs:px-5 md:px-[9rem] grid md:grid-cols-3 md:gap-20 2xl:gap-28'>
            <div className='w-fit'>
                <div>
                    <img src={CIOLogo} alt="CIO2024" className='md:w-[25.926vw]' />
                </div>
                <div className='mt-11'>
                    <p className='font-semibold text-brand-green text-[18px]'>Event Detail</p>
                    <div className='bg-[#FAFAFA] p-8 grid gap-4 mt-2'>
                        <div className='flex gap-2'>
                            <img src={calendar} alt="Date" />
                            <p className='text-[#747474] text-[18px]'>21 - 22 November, 2024</p>
                        </div>
                        <div className='flex gap-2'>
                            <img src={locationPin} alt="Location" />
                            <p className='text-[#747474] text-[18px] text-nowrap'>Mumbai, Maharashtra, India</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='md:col-span-2'>
                <div>
                    <p className='text-black text-44px font-semibold'>About <span className='text-brand-green font-semibold'>#</span><span className='text-[#7B7B7B] font-medium'>CIOIndia24</span></p>
                </div>
                <div className='mt-5'>
                    <p className='text-20px text-[#575757]'>Introducing Cancer Immunotherapy & Oncology India 2024—an esteemed annual event uniting top experts, researchers, policymakers, industry professionals, and stakeholders worldwide. Dedicated to advancing the prevention and treatment of cancer and cancer-related viral diseases using the immune system, this inaugural edition promises to be enlightening and impactful. #CIOIndia24 is committed to fostering the discovery, development, and implementation of innovative immunologic approaches. Join us for insightful discussions, exploration of emerging trends, and tackling critical challenges in global healthcare.</p>

                </div>
            </div>
        </div>
    )
}

export default About