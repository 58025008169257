import React from 'react'

function Home() {
    return (
        <div className='grid xs:min-h-[57vh] md:min-h-[59vh]'>
            <div className='z-[1] xs:mt-8 xs:mb-10 md:mb-0 md:mt-0 flex flex-col justify-center items-center'>
                <p className='xs:mb-14 md:mb-0 font-semibold min-text-[25px] text-[3.307vmax] text-white text-center'>Cancer Immunotherapy & Oncology, India 2024<span className="text-brand-green">.</span></p>
                <p className='text-white font-medium text-[20px] text-center'>Bringing together the pharmaceutical and biotech industries to connect communities globally.</p>
            </div>
        </div>
    )
}

export default Home