import React, { useRef, useEffect, useState } from 'react';
import heroBg from "../assets/banner.svg";
import Navbar from "./HomePage/Navbar";
import Home from "./HomePage/Home";
import About from "./HomePage/About";
import UpcomingEvents from "./HomePage/UpcomingEvents";
import Faq from "./HomePage/Faq";
import ContactUs from "./HomePage/ContactUs";
import Footer from "./HomePage/Footer";

function HomePage() {
    const [activeSection, setActiveSection] = useState("Home");
    const homeRef = useRef(null);
    const aboutUsRef = useRef(null);
    const eventsRef = useRef(null);
    const contactUsRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    setActiveSection(entry.target.id);
                }
            });
        }, { threshold: 0.9 });

        observer.observe(homeRef.current);
        observer.observe(aboutUsRef.current);
        observer.observe(eventsRef.current);
        observer.observe(contactUsRef.current);

        return () => observer.disconnect();
    }, []);

    const handleNavigate = (sectionId) => {
        const sectionRefs = {
            'Home': homeRef,
            'About Us': aboutUsRef,
            'Events': eventsRef,
            'Contact Us': contactUsRef
        };
        sectionRefs[sectionId].current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }

    return (
        <div>
            <div className="App bg-[#F5F5F5] poppins-regular">
                <img src={heroBg} alt="hero-bg" className="w-full xs:h-[90vh] md:h-[80vh] object-cover absolute top-0 z-[0]" />
                <Navbar active={activeSection} onNavigate={handleNavigate} />
                <div className='min-h-[80vh] w-full py-[1.827vh] px-[2.646vw] z-[1]'>
                    <section id="Home" className="min-h-[80vh] z-[1]" ref={homeRef}>
                        <Home />
                    </section>
                </div>
                <section id="About Us" className="relative min-h-[90vh] z-[1] py-[1.827vh] px-[2.646vw]" ref={aboutUsRef}><About /></section>
                <section id="Events" className="min-h-[85vh] z-[1]" ref={eventsRef}><UpcomingEvents /></section>
                <section id="FAQ" className="min-h-[95vh] z-[1] py-[1.827vh] px-[2.646vw]"><Faq /></section>
                <section id="Contact Us" className="min-h-[73vh] z-[1]" ref={contactUsRef}><ContactUs color="red" /></section>
                <Footer />
            </div>
        </div>
    )
}

export default HomePage

