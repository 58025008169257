import React, { useRef, useEffect, useState } from 'react';
import Navbar from './CIOEventPage/Navbar';
import heroBg from "../assets/CIOHero.svg"
import Home from './CIOEventPage/Home';
import CountdownTimer from './reusables/CountdownTimer';
import About from './CIOEventPage/About';
import WhyAttend from "./CIOEventPage/WhyAttend";
import WhoShouldAttend from './CIOEventPage/WhoShouldAttend';
import SessionTracks from './CIOEventPage/SessionTracks';
import PanelTracks from './CIOEventPage/PanelTracks';
import Sponsors from './CIOEventPage/Sponsors';
import Speakers from './CIOEventPage/Speakers';
import Footer from './CIOEventPage/Footer';
import ContactUs from './CIOEventPage/ContactUs';
import Register from './CIOEventPage/Register';
import ContactInfo from './CIOEventPage/ContactInfo';
import SponsorshipInfo from './CIOEventPage/SponsorshipInfo';
import OurAdvisors from './CIOEventPage/OurAdvisors';
import OurSpeakers from './CIOEventPage/OurSpeakers';


function CIOEventPage() {
    const [activeSection, setActiveSection] = useState("Home");
    const homeRef = useRef(null);
    const whyAttendRef = useRef(null);
    const sessionTracksRef = useRef(null);
    // const panelTracksRef = useRef(null);
    const speakersRef = useRef(null);
    const sponsorsRef = useRef(null);
    const eventRef = useRef(null);
    const aboutRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    setActiveSection(entry.target.id);
                }
            });
        }, { threshold: 0.5 });

        observer.observe(homeRef.current);
        observer.observe(whyAttendRef.current);
        observer.observe(sessionTracksRef.current);
        // observer.observe(panelTracksRef.current);
        observer.observe(speakersRef.current);
        observer.observe(sponsorsRef.current);
        observer.observe(eventRef.current);
        observer.observe(aboutRef.current);

        return () => observer.disconnect();
    }, []);

    const handleNavigate = (sectionId) => {
        const sectionRefs = {
            'Home': homeRef,
            "About": aboutRef,
            'Why Attend': whyAttendRef,
            'Session Tracks': sessionTracksRef,
            // 'Panel Tracks': panelTracksRef,
            'Speakers': speakersRef,
            'Sponsors': sponsorsRef,
            'Events': eventRef
        };
        sectionRefs[sectionId].current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }

    return (
        <div>
            <div className="App bg-[#F5F5F5] poppins-regular">
                <img src={heroBg} alt="hero-bg" className="w-[100vw] h-[80vh] object-cover absolute top-0 z-[0]" />
                <Navbar active={activeSection} onNavigate={handleNavigate} />
                <div className='xs:min-h-[57vh] md:min-h-[59vh] w-full py-[1.827vh] px-[2.646vw] z-[1]'>
                    <section id="Home" className="xs:min-h-[57vh] md:min-h-[59vh] z-[1]" ref={homeRef}>
                        <Home />
                    </section>
                </div>
                <div className='relative z-[1] xs:px-5 md:px-20 bg-[#181818] h-[10vh] max-w-[100vw] flex items-center text-32px text-[#878787]'>
                    <p className='xs:mr-10 md:mr-[12vw] text-[#878787]'>Starts In: </p>
                    <CountdownTimer targetDate={"2024-11-21"} />
                </div>
                <section id="About" className='min-h-[60vh] z-[1] py-[1.827vh] px-[2.646vw]' ref={aboutRef}><About /></section>
                <section id="Why Attend" className="min-h-[90vh] z-[1] bg-white" ref={whyAttendRef}><WhyAttend /></section>
                <section id="Who Should Attend" className="min-h-[85vh] z-[1]"><WhoShouldAttend /></section>
                <section id="Session Tracks" className="min-h-[90vh] z-[1]" ref={sessionTracksRef}><SessionTracks /> <OurAdvisors /></section>
                {/* <section id="Panel Tracks" className="min-h-[95vh] z-[1]" ref={panelTracksRef}><PanelTracks /></section> */}
                <section id="Speakers" className=" z-[1] h-fit pb-4" ref={speakersRef}><Speakers /></section>
                <section id="Sponsors" className="min-h-[73vh] h-fit z-[1]" ref={sponsorsRef}><Sponsors /><SponsorshipInfo /><ContactUs /><OurSpeakers /></section>
                <section id="Events" className='min-h-[30vh] z-[1] bg-white' ref={eventRef}><Register /><ContactInfo color={"green"} /></section>
                <Footer />
            </div>
        </div>
    )
}


export default CIOEventPage