import React, { useState } from 'react'
import RegisterCard from '../reusables/RegisterCard'
import RegisterCard2 from '../reusables/RegisterCard2'
import RegistrationForm from '../reusables/RegistrationForm';

function Register() {

    const [purchaseModal, setPurchaseModal] = useState(false);
    const pricingData = [
        {
            title: "For Companies",
            subTitle: "Pharma, Bio-Pharma, Biotech Pharma Logistics, etc",
            plan: 0,
            curr: "INR",
            pricing: {
                delegate: { type: 'Fee per Delegate', price: 14000, price2: 17000 },
                group3: { type: 'Group of 3 people', price: 38000, price2: 47000 },
                group5: { type: 'Group of 5 people', price: 50000, price2: 75000 },
            }
        },
        {
            title: "For Providers",
            subTitle: "Pharma Service Providers, Pharma Solution Service Providers",
            plan: 1,
            curr: "INR",
            pricing: {
                delegate: { type: 'Fee per Delegate', price: 35000, price2: 40000 },
                group3: { type: 'Group of 3 people', price: 100000, price2: 160000 },
                group5: { type: 'Group of 5 people', price: 175000, price2: 195000 },
            }
        }
    ]

    const studentsData = [
        {
            plan: 2,
            curr: "INR",
            amount: 7000,
            title: "Students",
            priceEarly: "Rs. 7000",
            priceRegular: "Rs. 8000",
            lunch: "Rs. 2000 /-",
            student: true
        },
        {
            plan: 3,
            curr: "USD",
            amount: 300,
            title: "International Delegates",
            priceEarly: "$ 300",
            priceRegular: "$ 350",
            lunch: "$ 75",
            student: false
        }
    ]
    return (
        <div>
            <div className='grid text-center justify-center py-[1.827vh] px-[2.646vw] '>
                <p className='mt-20 font-semibold text-48px'>Register for the event</p>
                <p className='font-medium text-16px text-[#727272]'>Choose the best suited event for you</p>
                {
                    pricingData.map((data) => {
                        return (
                            <div className='my-12' key={data.title}>
                                <RegisterCard plan={data.plan} curr={data.curr} title={data.title} subTitle={data.subTitle} priceDetails={data.pricing} />
                            </div>
                        )
                    })
                }
                <div className='grid justify-items-center'>
                    <div className='grid md:grid-cols-2 justify-items-center gap-2 w-[90vw] md:w-[77.245] 2xl:w-[78.845vw]'>
                        {
                            studentsData.map((data) => {
                                return (
                                    <div key={data.title} className=''>
                                        <RegisterCard2 plan={data.plan} curr={data.curr} amt={data.amount} title={data.title} priceEarly={data.priceEarly} priceRegular={data.priceRegular} lunch={data.lunch} student={data.student} />
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
                <div className='grid text-left justify-items-center'>
                    <div className='w-[90vw] md:w-[73.545vw] grid md:grid-cols-2 border border-solid border-[#DFDFDF] mt-10  xs:p-4 md:p-8'>

                        {
                            purchaseModal ? (
                                <div className='grid items-center justify-center w-full'>
                                    <div className='w-fit'>
                                        <RegistrationForm setModal={setPurchaseModal} plan={4} currency={"USD"} amount={200} />
                                    </div>
                                </div>
                            ) : (
                                <>
                                    <div className=''>
                                        <p className='text-[#231D4F] md:text-30px font-semibold mb-3'>Attend Event Virtually</p>
                                        <p className='mb-2 text-black text-20px'>Regular Price: <span className='font-bold text-brand-green text-30px'>Rs 6000/-</span></p>
                                        <p className='text-16px text-[#7E7E7E] mb-4'>On-Spot Price: Rs 7000/-</p>
                                    </div>
                                    <div className='flex items-center justify-center'>
                                        <button className='p-3 xs:w-[70vw] md:w-[29.894vw] bg-brand-green' onClick={() => setPurchaseModal(true)}>
                                            <p className='text-white text-16px'>Register</p>
                                        </button>
                                    </div>
                                </>
                            )
                        }
                    </div>
                </div>
                <div className='mt-10 bg-[#F3F3F3] border border-solid border-[#DFDFDF] xs:p-4 md:p-8 text-left grid items-center'>

                    <div className=''>
                        <p className='text-[#231D4F] md:text-30px font-semibold mb-3'>Coming in a group of more than 5 people?</p>
                        <p className='mb-2 text-[#828282] text-20px text-wrap'>You’ll be eligible to get an additional discount on the registration cost. To receive the discount coupon, please email us at <a href="mailto:saba.parveen@biosymposiums.com" className='underline text-brand-green text-wrap underline-color-brand-green'>saba.parveen@biosymposiums.com</a></p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Register