import React from 'react';
import CIOMediumScreen from "../../assets/CIOSponsorMediumScreen.svg";
function Speakers() {
    return (
        <div>
            <div className='flex flex-col items-center justify-center min-h-[37.5vh] bg-[#F5F5F5]'>
                <div className='grid items-center justify-center text-center'>
                    <p className='font-semibold text-32px'>Interested in becoming a speaker?</p>
                    <p className='font-medium text-20px text-[#727272]'>You can fill the Google form and we'll get back to you</p>
                </div>
                <div className='mt-12 bg-brand-green w-[27.91vw] min-w-[270px] h-[5.263vh] min-h-[48px] flex justify-center items-center hover:bg-brand-green/90'>
                    <a href="https://docs.google.com/forms/d/e/1FAIpQLSc-ocxaReqJS9njk1cBpsyXsXxOYHoatDS3WyxxV-nFXciFDA/viewform" target="_blank" rel="noopener noreferrer">
                        <button onClick={() => { }}>
                            <p className='font-semibold text-white text-16px'>Fill the Form</p>
                        </button>
                    </a>
                </div>
            </div>
            <img src={CIOMediumScreen} alt="Become a sponsor at #CIOIndia24. Boost your brand: Unlocking visibility with Tailored Solutions! Join hands with us to craft a customized package that aligns seamlessly with your business goals. Here you not only take the first step but also make it uniquely yours. " className='w-full xs:hidden md:block' />
            <div className="xs:block md:hidden bg-[url('assets/CIOSponsor.svg')] bg-cover bg-no-repeat">
                <div className='pt-20 mb-20 text-center'>
                    <p className='font-semibold text-white text-48px'>Become a sponsor at <span className='text-brand-green'>#</span><span className='font-medium'>CIOIndia24</span></p>
                    <p className='font-medium text-20px text-[#B8B8B8]'>Boost Your Brand: Unlocking Visibility with Tailored Solutions! </p>
                </div>
                <div className='flex flex-wrap text-center text-white text-26px mx-[12.037vw] pb-20'>
                    <q className='text-brand-green'><span className='text-white'>Join hands with us to craft a customized package that aligns seamlessly with your business goals. Here, you not only take the first step but also make it uniquely yours. </span></q>
                </div>
            </div>
        </div>
    )
}

export default Speakers