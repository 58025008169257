import React, { useEffect, useRef, useState } from 'react'

function WaitlistRegistrationForm({ setModal = () => { } }) {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [orgName, setOrgName] = useState("");
    const [phone, setPhone] = useState("");
    const [jobTitle, setJobTitle] = useState("");
    const [purchased, setPurchased] = useState(false);


    const purchaseCall = async () => {
        if (!name || !email || !phone) return;
        const purchaseIntent = await fetch("http://localhost:5000/joinwaitlist-intent", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                name,
                email,
                orgName,
                phone,
                jobTitle

            }),

        })
        if (purchaseIntent) setPurchased(true)
    }

    const formRef = useRef(null);

    useEffect(() => {
        if (formRef.current) {
            formRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, []);
    return (
        <div className=''>
            <div ref={formRef} className='my-auto bg-white grid justify-center p-6 z-[10] text-left'>
                {!purchased ? (
                    <form onSubmit={() => console.log("submit")}>
                        <div className=''>
                            <div className='font-semibold text-24px border-b border-solid border-[#DBDBDB] pb-4 mb-6'>
                                Join Waitlist
                            </div>
                            <div className='mb-6'>
                                <div className='flex gap-8 mb-4 xs:flex-wrap md:flex-nowrap'>
                                    <div className='w-full'>
                                        <label htmlFor="name" className='text-[12px]'>Name</label>
                                        <input type="text" id="name" onChange={(e) => setName(e.target.value)} placeholder='Enter Full Name' className='h-[5.263vh] min-h-[48px] w-full border border-solid border-brand-red placeholder:font-medium px-4 py-3 font-semibold text-black' value={name} required />
                                    </div>
                                    <div className='w-full'>
                                        <label htmlFor="email" className='text-[12px]'>Email</label>
                                        <input type="email" id="email" onChange={(e) => setEmail(e.target.value)} placeholder='Enter E-mail address' className='h-[5.263vh] min-h-[48px] w-full border border-solid border-brand-red placeholder:font-medium px-4 py-3 font-semibold text-black' value={email} required />
                                    </div>
                                </div>
                                <div className='flex gap-8 mb-4 xs:flex-wrap md:flex-nowrap'>
                                    <div className='w-full'>
                                        <label htmlFor="organization" className='text-[12px]'>Organization Name (Optional)</label>
                                        <input type="text" id="organization" onChange={(e) => setOrgName(e.target.value)} placeholder='Enter Organization Name' className='h-[5.263vh] min-h-[48px] w-full border border-solid border-brand-red placeholder:font-medium px-4 py-3 font-semibold text-black' value={orgName} />
                                    </div>
                                    <div className='w-full'>
                                        <label htmlFor="jobTitle" className='text-[12px]'>Job Title</label>
                                        <input type="text" id="jobTitle" onChange={(e) => setJobTitle(e.target.value)} placeholder='Eg: Founder' className='h-[5.263vh] min-h-[48px] w-full border border-solid border-brand-red placeholder:font-medium px-4 py-3 font-semibold text-black' value={jobTitle} required />
                                    </div>
                                </div>
                                <div className='grid grid-cols-1 md:grid-cols-2 gap-8 mb-4 xs:flex-wrap md:flex-nowrap'>
                                    <div className='w-full'>
                                        <label htmlFor="mobile" className='text-[12px]'>Mobile Number (with country code)</label>
                                        <input type="text" id="mobile" onChange={(e) => setPhone(e.target.value)} placeholder='Eg: +91 12345 67890' className='h-[5.263vh] min-h-[48px] w-full border border-solid border-brand-red placeholder:font-medium px-4 py-3 font-semibold text-black' value={phone} required />
                                    </div>
                                </div>
                            </div>
                            <div className='border-b border-solid border-[#DBDBDB] w-full mt-6'>

                            </div>
                            <div className='flex gap-x-8 xs:flex-wrap md:flex-nowrap h-[5.263vh] min-h-[48px] mt-6'>
                                <button className='w-full h-full' onClick={() => setModal(false)}>
                                    <div className='grid items-center h-full text-center transition-all duration-300 border border-solid text-brand-red hover:text-white hover:bg-brand-red border-brand-red hover:border-brand-red'>
                                        Cancel
                                    </div>
                                </button>
                                <button className='w-full h-full' type='submit' onClick={() => purchaseCall(true)}>
                                    <div className='grid items-center h-full text-center text-white transition-all duration-300 border border-solid border-brand-red bg-brand-red hover:bg-brand-red/80'>
                                        Join Waitlist
                                    </div>
                                </button>
                            </div>
                        </div>
                    </form>
                ) : (
                    <div className="font-semibold text-brand-red">
                        Thanks for Registering, See you at the event!
                    </div>
                )}
            </div>
        </div>
    )
}

export default WaitlistRegistrationForm