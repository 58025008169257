import React, { useState } from 'react'
import EventCard from '../reusables/EventCard'
import cancerAndOncologyImg from "../../assets/cancerAndOnco.svg";
import vaccineSummitImg from "../../assets/vaccineSummit.svg";
import H2 from '../reusables/H2';
import SubHeading from "../reusables/SubHeading"
import WaitlistRegistrationForm from '../reusables/WaitlistRegistration';

function UpcomingEvents() {
    const [waitlistModal, setWaitlistModal] = useState(false)
    const events = [{
        image: cancerAndOncologyImg,
        title: "Cancer Immunotherapy & Oncology - India",
        date: "21 -22 November, 2024",
        location: "India",
        linkItem: true,
        link: "cioindia24",
        modal: false,
        setter: () => { }
    }, {
        image: vaccineSummitImg,
        title: "Vaccines Summit - India",
        date: "April, 2025",
        location: "India",
        linkItem: false,
        link: "",
        modal: waitlistModal,
        setter: setWaitlistModal,
    }]
    return (
        <div className="bg-[url('assets/UpcomingEvents.svg')] bg-cover bg-no-repeat 2xl:bg-cover 2xl:bg-no-repeat min-h-[70vh] 2xl:min-h-[78vh]">
            <div className='py-[1.827vh] px-[2.646vw]'>
                <div className='mt-10 grid items-center justify-center'>
                    <H2>Upcoming Events</H2>
                    <SubHeading>Check our latest events happening</SubHeading>
                </div>
                <div className='flex justify-center xs:flex-wrap sm:flex-wrap'>
                    {
                        !waitlistModal ?
                            <>
                                {events.map((event) => {
                                    return <EventCard key={event.title} image={event.image} title={event.title} date={event.date} location={event.location} link={event.link} linkItem={event.linkItem} modal={event.modal} setter={event.setter} />
                                })}
                            </> :
                            <>
                                <WaitlistRegistrationForm setModal={setWaitlistModal} /> </>
                    }
                </div>
            </div>
        </div>
    )
}

export default UpcomingEvents