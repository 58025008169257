import React from 'react'
import CustomNavbar from './CustomNavbar'

function CancellationPolicyPage() {
    return (
        <>
            <CustomNavbar />
            <div className='grid pt-5 mt-10 justify-items-center'>
                <p>
                    <strong className='text-18px'>ALL CANCELLATIONS MUST BE SENT BY EMAIL AT saba.parveen@biosymposiums.com</strong>
                    <ul className='pt-10 pl-8 list-disc text-16px'>
                        <li className='py-2'>Cancellations received up to and including August 30, 2024: Full refund less 2,000/- (INR) towards the administrative fee.</li>
                        <li className='py-2'>Cancellations received between September 1, 2024, until October 30, 2024: 50% will be refunded.</li>
                        <li className='py-2'>Cancellations received After November 19, 2024: No refund will be made.</li>
                    </ul>

                </p>
            </div>
        </>
    )
}

export default CancellationPolicyPage