import React, { useState, useEffect } from 'react';
import plus from "../../assets/plus.svg";
import minus from "../../assets/minus.svg"

function Accordion({ title, description, borderColor = "border-brand-red" }) {
    const [isOpen, setIsOpen] = useState(false);
    const [shouldRender, setShouldRender] = useState(isOpen);

    useEffect(() => {
        if (isOpen) setShouldRender(true);
    }, [isOpen]);

    useEffect(() => {
        if (!isOpen && shouldRender) {
            const timer = setTimeout(() => setShouldRender(false), 50); // Delay matches transition duration
            return () => clearTimeout(timer);
        }
    }, [isOpen, shouldRender]);
    const descriptionLines = description.split("\n");

    return (
        <div className={`xs:p-5 md:p-10 ${isOpen ? "border border-solid " + borderColor : "shadow-[2px_2px_16px_rgba(30,94,255,0.08)]"} rounded-lg w-[75vw] ${isOpen ? "xs:min-h-[20vh] md:min-h-[13.848vh] md:max-h-[30.848vh]" : "xs:min-h-[10vh] md:min-h-[10.129vh] md:max-h-[12.129vh]"} transition-all ease-in-out duration-100`}>
            <div className='flex justify-between mb-5'>
                <p className='font-semibold text-24px'>{title}</p>
                <button onClick={() => setIsOpen(!isOpen)}>
                    <img src={isOpen ? minus : plus} alt="Open or Close FAQ" className='transition-all duration-300 cursor-pointer' />
                </button>
            </div>
            <div
                className={`${shouldRender ? "opacity-100" : "opacity-0 pointer-events-none"} transition-opacity ease-in-out duration-500`}
                style={{ display: shouldRender ? 'block' : 'none' }}
            >
                {shouldRender && descriptionLines.map((line, index) => (
                    <p key={index} className='text-[#7E84A3] text-16px'>{line}</p>
                ))}
            </div>
        </div>
    );
}
export default Accordion;