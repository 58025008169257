import React from 'react'

function Card({ title, image, description }) {
    return (
        <div className='group max-w-[90vw] md:max-w-[21.487vw] max-h-[40vh] md:min-h-[25.624vh] min-w-[300px] min-h-[207px] bg-white hover:bg-brand-red transition-colors duration-300 z-[1]'>
            <div className='p-6'>
                <div className='mb-6'>
                    <img src={image} alt={title} className='max-h-[56px] max-w-[56px] min-h-[52px] min-w-[52px]' />
                </div>
                <div className='mb-2'>
                    <h1 className='font-semibold text-[1.5rem] text-black group-hover:text-white transition-colors duration-300'>{title}</h1>
                </div>
                <div>
                    <p className='font-medium text-[1rem] text-[#747474] group-hover:text-white transition-colors duration-300'>{description}</p>
                </div>
            </div>
        </div>
    )
}

export default Card