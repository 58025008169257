import React from 'react';
import email from "../../assets/email.svg";
import phone from "../../assets/phone.svg";
import emailGreen from "../../assets/emailGreenLogo.svg";
import phoneGreen from "../../assets/phoneGreenLogo.svg";

function ContactDetails({ type, title, info, color }) {
    return (
        <a href={type === "email" ? `mailto:${info}` : `tel:${info}`}>
            <div className='flex gap-6 w-full items-center px-2 bg-[#F6F6F6]'>
                <div className='flex'>
                    <img src={type === "email" ? (color === "red" ? email : emailGreen) : (color === "red" ? phone : phoneGreen)} alt={type} className='h-[64px] w-[64px]' />
                </div>
                <div className='flex flex-col gap-2'>
                    <p className='font-semibold text-24px'>{title}</p>
                    <p className={`font-medium text-20px ${color === "red" ? "text-brand-red" : "text-brand-green"}`}>{info}</p>
                </div>
            </div>
        </a>
    )
}

export default ContactDetails