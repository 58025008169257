import React from 'react';
import H2 from '../reusables/H2';
import ContactDetails from '../reusables/ContactDetails';
import EmailForm from '../reusables/EmailForm';
import ContactCardPhone from '../reusables/ContactCardPhone';
import ContactCardEmailGeneral from '../reusables/ContactCardEmailGeneral';
import ContactCardEmailSpecial from '../reusables/ContactCardEmailSpecial';

function ContactUs({ color = "red" }) {
    const contactDetails = [
        {
            type: "phone",
            title: "Phone",
            info: "+91- 9697777768"
        }, {
            type: "email",
            title: "General Enquiry",
            info: "saksham.sundrani@biosymposiums.com"
        }, {
            type: "email",
            title: "Sponsor Enquiry",
            info: "saba.parveen@biosymposiums.com"
        }
    ]
    return (
        <div className='bg-white min-h-[73vh] pb-10'>
            <div className='py-[1.827vh] px-[2.646vw]'>
                <div className='mb-14'>
                    <H2>Get in Touch</H2>
                </div>
                <div className='grid md:grid-cols-2 xs:w-[90%] md:w-[80%] mx-auto xs:grid-cols-1 gap-[5.82vw]'>
                    <div className='flex flex-col justify-between'>
                        <div className=''>
                            <p className='font-semibold text-32px mb-[10px]'>Want to speak to us?</p>
                            <p className='font-medium text-22px text-[#979797]'>Connect with us via your suitable options, and we will get back to you very soon.</p>
                        </div>
                        <div className='flex flex-col gap-8 mt-8'>
                            {contactDetails.map((contact) => <div key={contact.title} className='xs:hidden md:block'><ContactDetails key={contact.title} {...contact} color={color} /></div>)}
                            <div className='xs:block md:hidden'>
                                <a href='tel:+919697777768'>
                                    <ContactCardPhone color={"#F62028"} />
                                </a>
                                <a href='mailto:kashish.jethwani@biosymposiums.com'>
                                    <ContactCardEmailGeneral color={"#F62028"} />
                                </a>
                                <a href='mailto:saba.parveen@biosymposiums.com'>
                                    <ContactCardEmailSpecial color={"#F62028"} />
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className=''>
                        <EmailForm extended={false} color={"red"} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactUs