import React, { useState, useEffect } from 'react';
import plus from "../../assets/plusGreen.svg";
import minus from "../../assets/minusGreen.svg"
import accordionBullet from "../../assets/AccordionBullet-green.svg"

function Accordion({ title, info, description, borderColor = "border-brand-green", img = null }) {
    const [isOpen, setIsOpen] = useState(false);
    const [shouldRender, setShouldRender] = useState(isOpen);

    useEffect(() => {
        if (isOpen) setShouldRender(true);
    }, [isOpen]);

    useEffect(() => {
        if (!isOpen && shouldRender) {
            const timer = setTimeout(() => setShouldRender(false), 50); // Delay matches transition duration
            return () => clearTimeout(timer);
        }
    }, [isOpen, shouldRender]);

    return (
        <div className={`xs:p-5 md:p-10 ${isOpen ? "border border-solid " + borderColor : "shadow-[2px_2px_16px_rgba(30,94,255,0.08)]"} rounded-lg w-[79.299vw] ${isOpen ? "xs:min-h-[20vh] md:min-h-[13.848vh] md:max-h-[40.848vh] h-fit" : "xs:min-h-[10vh] md:min-h-[10.129vh] md:max-h-[14.129vh] h-fit"} transition-all ease-in-out duration-100`}>
            <div className='flex items-center justify-between mb-5'>
                <div className='flex items-center gap-5'>
                    {img ? <img src={img} alt={title} className='w-[48px] h-[48px]' /> : null}
                    <p className='font-semibold text-24px'>{title}</p>
                </div>
                <button onClick={() => setIsOpen(!isOpen)}>
                    <img src={isOpen ? minus : plus} alt="Open or Close FAQ" className='transition-all duration-300 cursor-pointer' />
                </button>
            </div>
            <div className={`${shouldRender ? "opacity-100" : "opacity-0 pointer-events-none"} transition-opacity ease-in-out duration-500`}
                style={{ display: shouldRender ? 'block' : 'none' }}>
                <p>{info}</p>
            </div>
            <div
                className={`${shouldRender ? "opacity-100" : "opacity-0 pointer-events-none"} transition-opacity ease-in-out duration-500`}
                style={{ display: shouldRender ? 'block' : 'none' }}
            >
                {shouldRender && description.map((line) => (
                    <div key={line} className='flex gap-2' >
                        <img src={accordionBullet} alt="Bullet Point" />
                        <p className='text-[#7E84A3] text-16px'>{line}</p>
                    </div>
                ))}
            </div>
        </div>
    );
}
export default Accordion;