import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logo from "../../assets/BioSymposiums 1.svg";
import registerMic from "../../assets/register_mic_green.svg";

function Navbar({ active, onNavigate }) {
    const [isOpen, setIsOpen] = useState(false); // State to manage the hamburger menu visibility
    const handleClick = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    return (
        <div className={`sticky top-${active !== "Home" ? "0" : "3"}] flex justify-between items-center py-[1.827vh] px-[2.646vw] transition-all duration-300 z-[10] ${active !== "Home" ? "bg-white top-0 " : "bg-transparent top-3"}`}>
            <Link to="/" onClick={() => handleClick()}>
                <img src={logo} alt="BioSymposiums" className='min-h-[32px] h-8 w-[144.48px]' />
            </Link>
            <button className="md:hidden" onClick={() => setIsOpen(!isOpen)}>
                <svg className="w-6 h-6" fill="none" stroke="#638C11" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7"></path></svg>
            </button>
            <div className={`flex-col md:flex-row md:flex gap-4 ${isOpen ? 'xs:absolute xs:top-12 md:relative flex xs:w-[95%] md:w-auto bg-white bg-opacity-70 md:bg-opacity-0 p-2 md:p-0 rounded-md text-black' : 'hidden'} md:items-center`}>
                <ul className="flex flex-col md:flex-row md:gap-14 text-[16px] font-normal">
                    <li className={`${active === "Home" ? "text-brand-green" : active !== "Home" ? "text-black" : "xs:text-black md:text-brand-grey text-opacity-[64%]"} cursor-pointer transition-all duration-300`}
                        onClick={() => onNavigate('Home')}>
                        Home
                    </li>
                    <li className={`${active === "Why Attend" ? "text-brand-green" : active !== "Home" ? "text-black" : "xs:text-black md:text-brand-grey text-opacity-[64%]"} cursor-pointer transition-all duration-300`}
                        onClick={() => onNavigate('Why Attend')}>
                        Why Attend?
                    </li>
                    <li className={`${active === "Session Tracks" ? "text-brand-green" : active !== "Home" ? "text-black" : "xs:text-black md:text-brand-grey text-opacity-[64%]"} cursor-pointer transition-all duration-300`}
                        onClick={() => onNavigate('Session Tracks')}>
                        Event Programme
                    </li>
                    {/* <li className={`${active === "Panel Tracks" ? "text-brand-green" : active !== "Home" ? "text-black" : "xs:text-black md:text-brand-grey text-opacity-[64%]"} cursor-pointer transition-all duration-300`}
                        onClick={() => onNavigate('Panel Tracks')}>
                        Panel Tracks
                    </li> */}
                    <li className={`${active === "Speakers" ? "text-brand-green" : active !== "Home" ? "text-black" : "xs:text-black md:text-brand-grey text-opacity-[64%]"} cursor-pointer transition-all duration-300`}
                        onClick={() => onNavigate('Speakers')}>
                        Speakers
                    </li>
                    <li className={`${active === "Sponsors" ? "text-brand-green" : active !== "Home" ? "text-black" : "xs:text-black md:text-brand-grey text-opacity-[64%]"} cursor-pointer transition-all duration-300`}
                        onClick={() => onNavigate('Sponsors')}>
                        Sponsors
                    </li>
                </ul>
            </div>
            <div className='items-center hidden gap-2 px-2 py-1 bg-black border border-solid cursor-pointer md:flex border-brand-green bg-opacity-80' onClick={() => onNavigate('Events')}>
                <img src={registerMic} alt="register" className="w-6 h-6" />
                <span className={`font-semibold text-white `}>Register Now</span>
            </div>
        </div>
    )
}

export default Navbar;