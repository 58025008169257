import React from 'react';
import H2 from "../reusables/H2";
function About() {
    return (
        <div className="mt-10 md:bg-[url('assets/About.svg')] 2xl:bg-cover 2xl:bg-no-repeat min-h-[70vh] 2xl:min-h-[78vh] grid justify-center align-top">
            <H2>About BioSymposiums</H2>
            <div className='mt-10 md:ml-[5.5rem] 2xl:ml-0 2xl:mr-20 2xl:mt-14 flex flex-col gap-5 text-20px max-w-[80vw] md:max-w-[63vw] 2xl:max-w-[55vw] text-justify'>
                <p>
                    BioSymposiums stands out as a specialized conference organizing company dedicated to delivering impactful gatherings for biotech and pharma experts in India as well as internationally. With a focus on assembling key stakeholders, thought leaders, and innovators, we're committed to fostering connections and facilitating the exchange of knowledge to empower professionals in the field.

                </p>
                <p>
                    Understanding the dynamic needs of our clientele, we strive to develop engaging programs that offer thought-provoking keynote addresses, insightful panel discussions, interactive workshops, and invaluable networking opportunities. Our goal is to cultivate collaboration and drive innovation within the biotech and pharma community.

                </p>
                <p>
                    Dedicated to excellence, we take pride in our meticulous attention to detail, reliability, and professionalism in all aspects of our service delivery. As we embark on this journey, we extend a warm invitation to explore our upcoming events and participate in the transformative conversations that will contribute to the advancement of biotech and pharma. Join us at BioSymposiums, where knowledge meets opportunity.
                </p>
            </div>
        </div>
    )
}

export default About