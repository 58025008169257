import React, { useEffect, useRef, useState } from 'react'

function RegistrationForm({ setModal = () => { }, plan, currency, amount, food = false }) {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [orgName, setOrgName] = useState("");
    const [phone, setPhone] = useState("");
    const [coupon, setCoupon] = useState("");
    const [purchased, setPurchased] = useState(false);


    const purchaseCall = async () => {
        if (!name || !email || !phone) return;
        const purchaseIntent = await fetch("http://localhost:5000/purchase-intent", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                name,
                email,
                orgName,
                phone,
                coupon,
                plan,
                currency,
                amount,
                food,
            }),

        })
        if (purchaseIntent) setPurchased(true)
    }

    const formRef = useRef(null);

    useEffect(() => {
        if (formRef.current) {
            formRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, []);
    return (
        <div ref={formRef} className='bg-white grid justify-center p-6 z-[10] h-full w-full text-left'>
            {!purchased ? (
                <form onSubmit={() => console.log("submit")}>
                    <div className=''>
                        <div className='font-semibold text-24px border-b border-solid border-[#DBDBDB] pb-4 mb-6'>
                            Register for Event
                        </div>
                        <div className='mb-6'>
                            <div className='flex gap-8 mb-4 xs:flex-wrap md:flex-nowrap'>
                                <div className='w-full'>
                                    <label htmlFor="name" className='text-[12px]'>Name</label>
                                    <input type="text" id="name" onChange={(e) => setName(e.target.value)} placeholder='Enter your Name' className='h-[5.263vh] min-h-[48px] w-full border border-solid border-brand-green placeholder:font-medium px-4 py-3 font-semibold text-black' value={name} required />
                                </div>
                                <div className='w-full'>
                                    <label htmlFor="email" className='text-[12px]'>Email</label>
                                    <input type="email" id="email" onChange={(e) => setEmail(e.target.value)} placeholder='Enter your Email' className='h-[5.263vh] min-h-[48px] w-full border border-solid border-brand-green placeholder:font-medium px-4 py-3 font-semibold text-black' value={email} required />
                                </div>
                            </div>
                            <div className='flex gap-8 mb-4 xs:flex-wrap md:flex-nowrap'>
                                <div className='w-full'>
                                    <label htmlFor="organization" className='text-[12px]'>Organization Name (Optional)</label>
                                    <input type="text" id="organization" onChange={(e) => setOrgName(e.target.value)} placeholder='Enter Organization Name' className='h-[5.263vh] min-h-[48px] w-full border border-solid border-brand-green placeholder:font-medium px-4 py-3 font-semibold text-black' value={orgName} />
                                </div>
                                <div className='w-full'>
                                    <label htmlFor="mobile" className='text-[12px]'>Mobile Number (with country code)</label>
                                    <input type="text" id="mobile" onChange={(e) => setPhone(e.target.value)} placeholder='+91 12345 67890' className='h-[5.263vh] min-h-[48px] w-full border border-solid border-brand-green placeholder:font-medium px-4 py-3 font-semibold text-black' value={phone} required />
                                </div>
                            </div>
                            <div className='flex gap-8 mb-4 xs:flex-wrap md:flex-nowrap'>
                                <div className='w-full'>
                                    <label htmlFor="lunch/dinner" className='text-[12px]'>networking dinner</label>
                                    <input type="text" id="lunch/dinner" className='h-[5.263vh] min-h-[48px] w-full border border-solid border-brand-green placeholder:font-medium px-4 py-3 font-semibold text-black' value={food ? "Included" : "Not Included"} />
                                </div>
                                <div className='w-full'>
                                    <label htmlFor="coupon" className='text-[12px]'>Have a coupon?</label>
                                    <div className='flex h-[5.263vh] min-h-[48px] pl-4 pr-1  border border-solid border-brand-green'>
                                        <input type="text" id="coupon" onChange={(e) => setCoupon(e.target.value)} placeholder='Enter Coupon Code' className='w-full py-3 font-semibold text-black placeholder:font-medium active:border-none focus:outline-none' value={coupon} />
                                        <button onClick={() => setCoupon("")}>
                                            <div className='flex'>
                                                <div className='grid items-center text-center transition-all duration-300 border border-solid text-white hover:bg-brand-green/80 border-brand-green w-[7.738vw] min-w-[117px] h-[4.167vh] min-h-[38px] bg-brand-green'>
                                                    Apply
                                                </div>
                                            </div>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='bg-[#F3f3f3] px-6 py-4'>
                            <p className='mb-2 font-semibold text-black text-16px'>Coming in a group of more than 5 people?</p>
                            <p className='font-medium text-14px text-[#828282]'>You’ll be eligible to get an additional discount on the registration cost. To receive the discount coupon. <br className='xs:hidden md:block' /> please email us at: <a href="mailto:saba.parveen@biosymposiums.com"><span className='text-brand-green'>saba.parveen@biosymposiums.com</span></a></p>
                        </div>
                        <div className='border-b border-solid border-[#DBDBDB] w-full mt-6'>

                        </div>
                        <div className='grid grid-cols-2 gap-2 py-4 xs:justify-between md:justify-start border-b border-solid border-[#DBDBDB]'>
                            <p className='font-medium text-16px text-[#5E5E5E]'>Subtotal: </p> <p>{currency === "INR" ? "Rs." : "$"}{amount}</p>
                            <p className='font-medium text-16px text-[#5E5E5E]'>Tax:</p> <p>{currency === "INR" ? "Rs." : "$"}{(amount || 0) * 20 / 100}</p>
                            <p className='font-semibold text-18px'>Total Amount: </p> <p>{currency === "INR" ? "Rs." : "$"}{(amount || 0) + (amount || 0) * 20 / 100}</p>
                        </div>
                        <div className='flex gap-x-8 xs:flex-wrap md:flex-nowrap h-[5.263vh] min-h-[48px] mt-6'>
                            <button className='w-full h-full' onClick={() => setModal(false)}>
                                <div className='grid items-center h-full text-center transition-all duration-300 border border-solid text-brand-green hover:text-white hover:bg-brand-red border-brand-green hover:border-brand-red'>
                                    Cancel
                                </div>
                            </button>
                            <button className='w-full h-full' type='submit' onClick={() => purchaseCall(true)}>
                                <div className='grid items-center h-full text-center text-white transition-all duration-300 border border-solid border-brand-green bg-brand-green hover:bg-brand-green/80'>
                                    Proceed to Pay
                                </div>
                            </button>
                        </div>
                    </div>
                </form>
            ) : (
                <div className="font-semibold text-brand-green">
                    Thanks for Registering, See you at the event!
                </div>
            )}
        </div>
    )
}

export default RegistrationForm