import React, { useEffect, useState } from 'react';
// import immunotheraphyAdvance from "../../assets/ImmunoTheraphyAdvance.svg";
// import targetedTherapiesInCancer from "../../assets/TargetedTherapiesInCancerTreatment.svg";
// import immunoOncologyClinicalTrials from "../../assets/Immuno-OncologyClinicalTrials.svg";
// import exploringAdvances from "../../assets/ExploringAdvancesInCARTTheraphy.svg";
// import immunogenomics from "../../assets/ImmunogenomicsAndCancerImmunotheraphy.svg";
// import cancerVaccines from "../../assets/CancerVaccines_CurrentTrendsAndFutureDirections.svg";
// import precisionOncology from "../../assets/PrecisionOncologyInnovations.svg";
// import emergingBiomarkers from "../../assets/EmergingBiomarkersInOncology.svg";
// import immunotheraphyCombinationStrat from "../../assets/ImmunotheraphyCombinationStrategies.svg";
// import immuneCheckpointInhibitors from "../../assets/ImmuneCheckpointInhibitors.svg";
// import overcomingResistanceToImmuneotherapy from "../../assets/OvercomingResistanceToImmunotheraphy.svg";
// import adoptiveCellTherapiesInCancer from "../../assets/AdoptiveCellTherapiesInCancerTreatment.svg";
import inauguralSpeaker from "../../assets/InaugaralSpeaker.svg"
import cellularAndGene from "../../assets/CellularAndGeneTheraphies.svg"
import innovationsAndChallenges from "../../assets/InnivationAndChallengesinClinicalTrials.svg"
import harnessBigData from "../../assets/HarnessingBigData.svg";
import advancesinSmallMolecule from "../../assets/AdvancesInSmallMolecule.svg";
import antibodies from "../../assets/AntibodiesBasedTheraputics.svg";
import navigatingRegulatoryLandscapes from "../../assets/NavigatingRegulatory.svg";
import futureHorizons from "../../assets/FutureHorizonsInCancerImmunotherapy.svg"


function SessionTracks() {
    const [active, setActive] = useState(-1);
    const dataList = [
        [{
            heading: "Inaugural Speaker - Advances in Cancer Therapy",
            list: [
                "Cutting-Edge Treatments: Overview of the latest breakthroughs in cancer therapy, including targeted therapies and immunotherapies.",
                "Mechanisms of Action: Understanding how new treatments work at the molecular and cellular levels.",
                "Clinical Trials and Outcomes: Key findings from recent clinical trials and their implications for future treatment protocols.",
                "Combination Therapies: Exploring the efficacy of combining different therapeutic modalities to enhance patient outcomes."
            ]
        }],
        [
            {
                heading: "Session Chair : Small Molecule Therapies in Oncology: Current Innovations and Future Directions",
                list: [
                    "Evolution of Small Molecule Therapies: From traditional approaches to cutting-edge innovations.",
                    "Technological Advances: Next - generation small molecule inhibitors and degraders.",
                    "Clinical Impact: Successes and challenges in using small molecules for cancer treatment.",
                    "Future Directions: Emerging trends and potential applications in personalized oncology.",
                ]
            },
            {
                heading: "Session 1: Enhancing Cancer Immunotherapy: Insights into Checkpoint Inhibitors, Immunoagonists, and Overcoming Resistance",
                list: ["Mechanisms of Action: How checkpoint inhibitors and immune agonists enhance immune responses against cancer.",
                    "Clinical Applications: Successful examples and outcomes in immunotherapy.",
                    "Resistance Mechanisms: Strategies to overcome resistance to checkpoint inhibitors and immune agonists.",
                    "Innovation and Future Developments: Advances in enhancing the efficacy and durability of immunotherapies."]
            },
            {
                heading: "Session  2: Targeted Therapy Strategies: Small Molecule Inhibitors, Targeted Protein Degraders, and Their Clinical Applications",
                list: [
                    "Targeted Oncology: Mechanisms and clinical applications of small molecule inhibitors in oncology.",
                    "Targeted Protein Degraders: Advantages and applications in targeted protein degradation for cancer therapy.",
                    "Small Molecule Inhibitors: Specific examples and successes in using small molecules to target cancer pathways.",
                    "Future Innovations: Novel approaches and advancements in small molecule therapy research",
                ]
            },
            {
                heading: "Case Study: Case Studies of Breakthroughs in Checkpoint Inhibitors and Immunoagonists",
                list: [
                    "Highlighting significant cases demonstrating the effectiveness of checkpoint inhibitors and immune agonists in cancer immunotherapy.",
                ]
            },
            {
                heading: "Panel Discussion: Advances in Small Molecule Agents: Developments and Future Directions",
                list: [
                    "Breakthrough Innovations: Exploring the latest advancements in small molecule agents.",
                    "Development Strategies: Overcoming design and efficacy challenges.",
                    "Clinical Applications: Success stories and patient outcomes.",
                    "Future Prospects: Emerging trends and potential future developments."
                ]
            }
        ],
        [

            {
                heading: "Session Chair : The Evolution and Future of Cell and Gene Therapy",
                list: [
                    "Current Landscape: Overview of recent advancements in CAR-T and CAR-NK therapies.",
                    "Emerging Technologies: Innovations driving the next generation of cell and gene therapies.",
                    "Clinical Impact: Real-world implications and successes of cell and gene therapies.",
                    "Future Prospects: Vision for the future of cell and gene therapy in oncology."
                ]
            },
            {
                heading: "Session 1: Breakthroughs in CAR-T Cell Therapy",
                list: [
                    "Mechanisms of Action: How CAR-T cells are designed to target and eliminate cancer cells.",
                    "Clinical Success Stories: Highlighting major achievements and success rates in CAR-T therapy.",
                    "Overcoming Obstacles: Strategies to address current challenges such as relapse and resistance.",
                    "Future Developments: Innovations aimed at enhancing CAR-T cell efficacy, safety, and accessibility."
                ]
            },
            {
                heading: "Session 2: CAR-NK Cells: Expanding the Frontiers of Immunotherapy",
                list: [
                    "Introduction to CAR-NK Cells: Basics and potential advantages of CAR-NK therapy compared to other immunotherapies.",
                    "Clinical Trials: Recent developments, ongoing trials, and results from CAR-NK cell therapy studies.",
                    "Therapeutic Potential: Unique benefits and applications of CAR-NK cells in different types of cancers.",
                    "Innovation and Future Directions: Advances in CAR-NK cell engineering, manufacturing, and application."
                ]
            },
            {
                heading: "Case Study: Real-World Impact of Cell and Gene Therapies in Oncology",
                list: [
                    "Highlighting significant cases demonstrating the effectiveness of cell and gene therapies across different cancer types."
                ]
            },
            {
                heading: "Panel Discussion: Advances in Cellular and Gene Therapies: Developments and Future Directions",
                list: [
                    "Technological Innovations: Latest techniques in cellular and gene therapy.",
                    "Clinical Impact: Case studies and patient outcomes.",
                    "Development Challenges: Addressing production and scalability issues.",
                    "Future Directions: New horizons and upcoming innovations."
                ]
            }


        ],
        [

            {
                heading: "Session Chair : Transforming Medicine with Antibody-Based Therapeutics",
                list: [
                    "Overview of the evolution of antibody-based therapeutics, from mAbs to bispecifics and ADCs",
                    "Mechanisms of action and clinical applications of each modality",
                    "Emerging trends and technologies in antibody engineering and development",
                    "Future directions and potential combinations with other therapeutic approaches"
                ]
            },
            {
                heading: "Session 1: Monoclonal Antibodies (mAbs): Mechanisms and Clinical Applications",
                list: [
                    "Mechanisms of Action: How mAbs target specific antigens on cells and modulate immune responses.",
                    "Therapeutic Successes: Case studies demonstrating the efficacy of mAbs in oncology and autoimmune diseases.",
                    "Improving Treatment Outcomes: Strategies to enhance mAb efficacy, reduce resistance, and manage adverse effects.",
                    "Future Directions: Innovations in mAb engineering and their implications for future therapies."
                ]
            },
            {
                heading: "Session 2: Bispecific Antibodies and Antibody-Drug Conjugates (ADCs): Advancing Targeted Therapy",
                list: [
                    "Bispecific Antibodies: Design principles and therapeutic applications in redirecting immune responses.",
                    "ADCs: Combining the specificity of antibodies with cytotoxic agents for targeted cancer therapy.",
                    "Clinical Trials and Innovations: Recent developments and outcomes in bispecifics and ADC therapies.",
                    "Future Innovations: Next-generation approaches in bispecifics and ADCs for improved cancer treatment."
                ]
            },
            {
                heading: "Case Study: Transformative Impact of Antibody-Based Therapeutics",
                list: [
                    "Highlighting significant advancements and stories in mAbs, bispecifics, and ADCs across various diseases."
                ]
            },
            {
                heading: "Panel Discussion: Pioneering Antibody-Based Therapeutics and Future Directions",
                list: [
                    "Antibody Design Innovations: Advances in monoclonal and bispecific antibody development.",
                    "Clinical Impact: Case studies showcasing efficacy and safety.",
                    "Overcoming Resistance: Addressing challenges and enhancing therapeutic specificity.",
                    "Future Applications: Potential new uses and technologies on the horizon."
                ]
            }

        ],
        [

            {
                heading: "Session Chair : Evolution of Clinical Trial Designs in Oncology",
                list: [
                    "Historical Perspective: Evolution from traditional trial designs to modern adaptive and personalized approaches.",
                    "Innovative Trial Designs: Overview of adaptive, basket, and umbrella trial designs in oncology.",
                    "Precision Medicine Integration: Incorporating biomarkers and molecular profiling into trial designs.",
                    "Future Trends: Emerging technologies and methodologies shaping the future of clinical trials in oncology."
                ]
            },
            {
                heading: "Session 1: Designing and Conducting Phase I/II Trials in Immunotherapy",
                list: [
                    "Phase I Trial Design: Design considerations for early-phase trials assessing safety and dosage.",
                    "Phase II Trial Objectives: Evaluating efficacy and refining dosage in larger patient cohorts.",
                    "Immunotherapy Challenges: Addressing unique challenges in immunotherapy trial design, such as immune-related adverse events.",
                    "Case Studies: Successful examples of phase I/II trials in immunotherapy and their outcomes."
                ]
            },
            {
                heading: "Session 2: Novel Approaches in Clinical Trial Methodologies",
                list: [
                    "Adaptive Trial Designs: Benefits and challenges in adapting trials based on accumulating data.",
                    "Virtual and Decentralized Trials: Utilizing technology to conduct trials remotely.",
                    "Biomarker-Driven Trials: Personalizing treatments based on patient biomarkers.",
                    "Real-World Evidence: Incorporating real-world data into clinical trial outcomes."
                ]
            },
            {
                heading: "Case Study: General Impact of Adaptive Trial Designs on Drug Development",
                list: [
                    "Demonstrating Accelerated Drug Development Through Adaptive Trial Designs"
                ]
            },
            {
                heading: "Panel Discussion: Transformations in Clinical Trials Design and Future Directions",
                list: [
                    "Innovative Methodologies: Adaptive, virtual, and decentralized clinical trial designs.",
                    "Navigating Regulations: Overcoming global regulatory challenges.",
                    "Patient-Centric Approaches: Enhancing recruitment and retention strategies.",
                    "Future Innovations: Anticipating and preparing for future trends in clinical trial design."
                ]
            }

        ],
        [

            {
                heading: "Session Chair : Regulatory Challenges and Innovations in Cancer Therapy",
                list: [
                    "Global Regulatory Frameworks: Explore the evolving regulatory requirements for cancer immunotherapies across major markets, including the US, EU, and Asia.",
                    "Approval Pathways: Discuss accelerated approval pathways, including breakthrough therapy designations and conditional approvals, specific to oncology.",
                    "Compliance and Safety Monitoring: Examine the latest standards for ensuring safety and efficacy in clinical trials and post-market surveillance.",
                    "Future Directions: Insights into upcoming regulatory trends and potential changes in policies impacting cancer immunotherapy development."
                ]
            },
            {
                heading: "Session 1: Harmonizing International Standards in Oncology Regulatory Affairs",
                list: [
                    "Cross-Border Collaborations: The importance of international cooperation for standardizing regulatory practices.",
                    "ICH Guidelines: Implementation and impact of the International Council for Harmonisation (ICH) guidelines in cancer immunotherapy.",
                    "Case Studies: Real-world examples of successful harmonization efforts and their outcomes.",
                    "Best Practices: Strategies for aligning global regulatory submissions to streamline approvals and market entry."
                ]
            },
            {
                heading: "Session 2: Optimizing Regulatory Strategies for Early-Phase Cancer Therapy Trials",
                list: [
                    "Preclinical Requirements: Key regulatory considerations for preclinical studies in immunotherapy.",
                    "Phase I Trial Design: Designing robust early-phase trials to meet regulatory expectations and ensure patient safety.",
                    "Regulatory Submissions: Effective preparation and submission of INDs and other regulatory documents for early-phase trials.",
                    "Risk Management: Identifying and mitigating risks in early-phase clinical development to comply with regulatory standards."
                ]
            },
            {
                heading: "Case Study : Accelerated Approval Pathways for Therapy Treatments",
                list: [
                    "Lessons learned in navigating regulatory hurdles and achieving timely approvals."
                ]
            },
            {
                heading: "Panel Discussion: Advances in Regulatory Landscapes: Development and Future Directions",
                list: [
                    "Regulatory Innovations: Impact of recent changes on cancer therapy development.",
                    "Ensuring Compliance: Strategies for adhering to evolving standards and guidelines.",
                    "Global Harmonization: Achieving consistent regulatory practices internationally.",
                    "Anticipating Changes: Preparing for future regulatory trends and challenges."
                ]
            }


        ],
        [

            {
                heading: "Session Chair : Revolutionizing Cancer Therapy with AI and Emerging Technologies",
                list: [
                    "Integration of AI in Immunotherapy: Explore how AI algorithms are transforming the development of personalized cancer immunotherapies.",
                    "Predictive Analytics for Treatment Outcomes: Discuss the role of AI in predicting patient responses to various immunotherapy treatments.",
                    "Data-Driven Drug Discovery: Examine case studies where AI has accelerated the identification of new therapeutic targets and drug candidates.",
                    "Future Prospects: Highlight upcoming technological advancements and their potential impact on cancer treatment paradigms."
                ]
            },
            {
                heading: "Session : Machine Learning in Personalized Cancer Therapy",
                list: [
                    "Tailoring Treatments: Understand how machine learning models are used to develop individualized treatment regimens for cancer patients.",
                    "Patient Monitoring: Discover the use of AI in continuous monitoring and real-time adjustment of cancer therapies.",
                    "Outcome Prediction: Review how machine learning algorithms can predict patient outcomes and optimize treatment plans accordingly.",
                    "Case Studies: Present real-world applications and success stories of machine learning in personalizing cancer treatment."
                ]
            },
            {
                heading: "Case Study : Integrating Big Data in Clinical Practice",
                list: [
                    "Benefits and challenges of adopting big data technologies in clinical settings."
                ]
            },
            {
                heading: "Panel Discussion: Advances in Big Data for Cancer Immunotherapy and Oncology: Development and Future Directions",
                list: [
                    "Data Integration Strategies: Combining clinical, genomic, and real-world data for insights.",
                    "AI and Machine Learning: Utilizing advanced analytics to enhance personalized treatment.",
                    "Ethical Considerations: Addressing privacy and security concerns in big data use.",
                    "Future Technologies: Exploring upcoming innovations and their potential impact on oncology."
                ]
            }


        ],
        [

            {
                heading: "Session Chair : Vision 2030: Future Trends and Innovations in Cancer Immunotherapy and Oncology",
                list: [
                    "Evolution of Cancer Immunotherapy: From early successes to future directions in personalized treatments.",
                    "Emerging Technologies: AI, machine learning, and big data analytics in advancing immunotherapy.",
                    "Next-Generation Therapies: CAR-T cells, personalized vaccines, and immune checkpoint inhibitors.",
                    "Global Impact: Shaping global oncology practices and improving patient outcomes."
                ]
            },
            {
                heading: "Panel Discussion: Integrating Insights from Across the Event: Medical, Regulatory, Clinical, and Technological Perspectives",
                list: [
                    "Medical Perspectives: Key takeaways on the latest advancements in cancer therapy, small molecule agents, cellular and gene therapies, and antibody-based therapeutics.",
                    "Regulatory Insights: Discussing the evolving regulatory landscapes and their impact on the development and approval of new therapies.",
                    "Clinical Trial Innovations: Examining the challenges and innovations in clinical trial designs, patient recruitment, and ethical considerations.",
                    "Technological Integration: Exploring the role of big data, AI, and machine learning in revolutionizing cancer treatment and research."
                ]
            }


        ]

    ]

    useEffect(() => {
        if (active !== -1) {
            document.body.classList.add('no-scroll');
        } else {
            document.body.classList.remove('no-scroll');
        }

        // Clean up function to remove the class when the component unmounts
        return () => {
            document.body.classList.remove('no-scroll');
        };
    }, [active]);

    return (
        <div className="min-h-[75vh] pt-1 pb-20">
            <div className='grid text-center justify-center py-[1.827vh] px-[2.646vw] '>
                <p className='mt-20 font-semibold text-48px'>Event Programme</p>
                <p className='font-medium text-16px text-[#727272]'>Check out the tracks in the sessions</p>
            </div>
            <div className='xs:mx-5 md:mx-[9.656vw] grid gap-x-14 justify-center gap-5 xs:mt-4 2xl:mt-16 md:grid-cols-2'>
                <div className='grid gap-5 md:gap-12 justify-items-center'>
                    <div className='w-fit bg-brand-green text-white font-bold px-10 py-2 xl:scale-125'>Day 1</div>
                    {/* <img src={immunotheraphyAdvance} alt="Immunotherapy Advance" />
                    <img src={targetedTherapiesInCancer} alt="Targeted Therapies in Cancer Treatment" />
                    <img src={immunoOncologyClinicalTrials} alt="Immuno-oncology Clinical Trials" />
                    <img src={exploringAdvances} alt="Exploring Advances in CAR T Therapy" />
                    <img src={immunogenomics} alt="Immunogenomics and Cancer Immunotherapy" />
                    <img src={cancerVaccines} alt="Cancer Vaccines: Current Trends and Future Directions" /> */}
                    <img src={inauguralSpeaker} alt="Inaugural Speaker - Advances in Cancer Therapy Advance" className='cursor-pointer' onClick={() => setActive(1)} />
                    <img src={advancesinSmallMolecule} alt="Advanices in Small Molecule Agents" className='cursor-pointer' onClick={() => setActive(2)} />
                    <img src={cellularAndGene} alt="Cellular and Gene Therapies" className='cursor-pointer' onClick={() => setActive(3)} />
                    <img src={antibodies} alt="Antibodies Based Therapeutics" className='cursor-pointer' onClick={() => setActive(4)} />
                </div>
                <div className='grid gap-5 md:gap-12 justify-items-center'>
                    <div className='w-fit bg-brand-green text-white font-bold px-10 py-2 xl:scale-125'>Day 2</div>
                    {/* <img src={precisionOncology} alt="Precision Oncology Innovations" />
                    <img src={emergingBiomarkers} alt="Emerging Biomarkers in Oncology" />
                    <img src={immunotheraphyCombinationStrat} alt="Immunotheraphy Combination Strategies" />
                    <img src={immuneCheckpointInhibitors} alt="Immune Checkpoint Inhibitors" />
                    <img src={overcomingResistanceToImmuneotherapy} alt="Overcoming Resistance to Immunotherapy" />
                    <img src={adoptiveCellTherapiesInCancer} alt="Adoptive Cell Therapies in Cancer Treatment" /> */}
                    <img src={innovationsAndChallenges} alt="Innovation and Challenges in Clinical Trials" className='cursor-pointer' onClick={() => setActive(5)} />
                    <img src={navigatingRegulatoryLandscapes} alt="Navigating Regulatory Landscapes in Cancer Therapy" className='cursor-pointer' onClick={() => setActive(6)} />
                    <img src={harnessBigData} alt="Harnessing Big Data for Advancements in Cancer Immunotherapy" className='cursor-pointer' onClick={() => setActive(7)} />
                    <img src={futureHorizons} alt="Future Horizons in Cancer Immunotherapy and Oncology" className='cursor-pointer' onClick={() => setActive(8)} />
                </div>
            </div>
            {active !== -1 &&
                <div className='fixed top-0 left-0 z-50 grid justify-center w-full h-full bg-black/50 place-items-center' onClick={() => setActive(-1)}>
                    <div className='relative bg-white rounded-md h-[95vh] md:h-[80vh] w-[95vw] md:w-[80vw] p-4 overflow-scroll' onClick={(e) => e.stopPropagation()}>
                        <div className='absolute cursor-pointer top-2 right-6 hover:text-brand-red' onClick={() => setActive(-1)}>X</div>
                        <div className='p-8'>
                            {dataList[active - 1].map((item, index) => {
                                return (
                                    <div className='mb-10'>
                                        <div className='mb-4 font-bold text-24px'>{item.heading}</div>
                                        <div>
                                            <ul className='pl-14'>
                                                {
                                                    item.list.map((listItem, listIndex) => {
                                                        return <li key={listIndex} className='list-disc text-18px text-[#656565] my-1'>{listItem}</li>
                                                    })
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>

                    </div>
                </div>
            }
        </div>
    )
}

export default SessionTracks