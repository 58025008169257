import React from 'react'
import AccordionWithBullets from '../reusables/AccordionWithBullets';
import connection from "../../assets/connect-green.svg";
import growth from "../../assets/growth-green.svg";
import stars from "../../assets/stars.svg";

function WhyAttend() {

    const whyAttendInfo = [
        {
            title: "Elevate your business year-round",
            info: "Bringing together the best in pharma and biotech across South Asia, the event name/company name offers a distinctive setting where industry leaders meet, learn, share ideas, and drive their hai businesses forward year-round.",
            description: ["Discover companies and products", "Engage with like-minded professionals", "Learn from industry leaders"],
            img: growth,
        },
        {
            title: "Unmatched Connections: Elevating Relationships through In-person Networking",
            info: "Discover cutting-edge pharmaceutical machinery, laboratory equipment, analytical technology, and clinical research at event name/company name. Engage in valuable in-person networking and educational sessions to stay ahead in the field.",
            description: ["Place yourself at the heart of pharma", "Attend talks, panels, and presentation", "Experience the power of face-to-face"],
            img: connection,
        },
        {
            title: "Fostering connections from introductions",
            info: "Access conference content anytime and engage with the Biosymposiums community year-round. Join us to move your conversations forward.",
            description: ["Develop connections, grow your business", "Engage all year round on event name/company name", "Turn missed opportunities into future partnerships", "Watch all event content on-demand"],
            img: stars,
        },
    ]

    return (
        <div className="bg-[url('assets/UpcomingEvents.svg')] bg-no-repeat bg-cover min-h-[75vh] pb-20">
            <div className='py-[1.827vh] px-[2.646vw] grid justify-center text-center'>
                <p className='mt-20 font-semibold text-48px'>Why Attend?</p>
                <p className='font-medium text-16px text-[#727272]'>There are many benefits of attending this event. Check out the points to know more</p>
            </div>
            <div className='grid gap-5 mt-16'>
                {whyAttendInfo.map((information) => {
                    return (
                        <div key={information.title} className='grid justify-center gap-10'>
                            <AccordionWithBullets title={information.title} info={information.info} img={information.img} description={information.description} />
                        </div>
                    )
                })}

            </div>
        </div>
    )
}

export default WhyAttend