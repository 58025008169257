import React, { useState, useEffect } from 'react';

function CountdownTimer({ targetDate }) {
    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(targetDate));

    useEffect(() => {
        const timer = setInterval(() => {
            const newTimeLeft = calculateTimeLeft(targetDate);
            setTimeLeft(newTimeLeft);
            if (newTimeLeft.total <= 0) {
                clearInterval(timer);
            }
        }, 1000);

        return () => clearInterval(timer);
    }, [targetDate]);

    function calculateTimeLeft(targetDate) {
        const difference = +new Date(targetDate) - +new Date();
        let timeLeft = {};

        if (difference > 0) {
            timeLeft = {
                days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                minutes: Math.floor((difference / 1000 / 60) % 60),
                seconds: Math.floor((difference / 1000) % 60),
                total: difference
            };
        }

        return timeLeft;
    }
    return (
        <div className='flex xs:gap-6 md:gap-16'>
            <div className='p-2  flex items-center justify-center bg-black/80 h-16 min-w-[9.5vw] gap-2'>
                <span className='poppins-semibold text-brand-green'>{timeLeft.days} </span>
                <span className='text-white poppins-light'> days</span>
            </div>
            <div className='p-2  flex items-center justify-center bg-black/80 h-16 min-w-[9.5vw] gap-2'>
                <span className='poppins-semibold text-brand-green'>{timeLeft.hours}</span>
                <span className='text-white poppins-light'> hours</span>
            </div >
            <div className='p-2 xs:hidden md:flex items-center justify-center bg-black/80 h-16 min-w-[9.5vw] gap-2'>
                <span className='poppins-semibold text-brand-green'>{timeLeft.minutes} </span>
                <span className='text-white poppins-light'> mins</span>
            </div >
            <div className='p-2 xs:hidden md:flex items-center justify-center bg-black/80 h-16 min-w-[9.5vw] gap-2'>
                <span className='poppins-semibold text-brand-green'>{timeLeft.seconds}</span>
                <span className='text-white poppins-light'> sec</span>
            </div>
        </div>
    );
}

export default CountdownTimer;