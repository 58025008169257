import React from 'react'
import CustomNavbar from './CustomNavbar';

function PrivacyPolicyPage() {
    return (
        <>
            <CustomNavbar />
            <div className="px-[5vw] pt-[1vw] pb-[2vh] space-y-4">
                <h1 className="mb-4 text-3xl font-bold">Privacy Policy</h1>

                <p className="leading-relaxed">
                    At BioSymposiums, we are dedicated to safeguarding your privacy. We are transparent about how we collect, use, share, process, and protect your personal information when you use our services and visit our websites. Our privacy policy is regularly updated and any changes will be communicated through updates on our website and alerts or pop-ups.
                </p>

                <p className="leading-relaxed">
                    We want you to have a clear understanding of how we handle your information, so we encourage you to review our privacy policy carefully. Please note that this policy may be updated periodically and your continued use of our website constitutes acceptance of any changes. We recommend checking this privacy policy periodically for any updates.
                </p>

                <h2 className="mt-6 text-2xl font-semibold">How to use this Privacy Policy?</h2>
                <p className="leading-relaxed">
                    In this Privacy Policy, we detail the methods by which we gather, utilize, share, process, and safeguard your personal information while you engage with our products, services, and websites.
                </p>

                <h2 className="mt-6 text-2xl font-semibold">How do we collect your Information?</h2>
                <ul className="pl-4 space-y-2 list-disc list-inside">
                    <li>Enroll for a particular conference</li>
                    <li>Sign up for newsletters, alerts, or other services provided by us</li>
                    <li>Reach out to us via different communication channels or inquire about an event, publication, product, or service</li>
                    <li>Explore or navigate our website</li>
                    <li>Where your information is accessible from another publicly available source</li>
                </ul>

                <h2 className="mt-6 text-2xl font-semibold">What Information do we Collect?</h2>
                <p className="leading-relaxed">We collect various types of personal data, including:</p>
                <ul className="pl-4 space-y-2 list-disc list-inside">
                    <li>Contact details such as name, email address, postal address, and telephone number</li>
                    <li>Educational, nationality, and professional information</li>
                    <li>Demographic information like gender and birth date</li>
                    <li>Usernames and passwords</li>
                    <li>Payment information such as credit or debit card numbers or bank account details</li>
                    <li>Comments, feedback, ratings, posts, and other submitted content, including survey responses</li>
                    <li>Interests and communication preferences, including marketing permissions as applicable</li>
                    <li>Location information obtained through mobile apps and Bluetooth beacons</li>
                    <li>Identity verification details such as passport information to fulfill legal requirements and to issue visa invitation letters upon request</li>
                    <li>IP address, website usage data, and communication patterns collected through cookies or similar tracking technologies</li>
                    <li>Name of the hotel when attending one of our events</li>
                </ul>

                <h2 className="mt-6 text-2xl font-semibold">Cookies and Similar Tracking Technologies</h2>
                <p className="leading-relaxed">
                    We employ automated means to collect specific information utilizing technologies like cookies, pixel tags, browser analysis tools, server logs, and web beacons. Upon visiting our website, certain cookies are placed on your device. Please refer to our comprehensive policy regarding the use of cookies and other tracking technologies, including your options concerning such utilization.
                </p>

                <h2 className="mt-6 text-2xl font-semibold">How and why do we use your Information?</h2>
                <p className="leading-relaxed">We utilize your personal information for the following purposes:</p>
                <ul className="pl-4 space-y-2 list-disc list-inside">
                    <li>Engaging with you to provide information regarding our upcoming conferences and services</li>
                    <li>Addressing your inquiries and feedback</li>
                    <li>Managing product inquiries, sales, and administrative tasks</li>
                    <li>Overseeing event and exhibition logistics</li>
                    <li>Facilitating our publishing services</li>
                    <li>Conducting marketing activities</li>
                    <li>Utilizing social media platforms</li>
                    <li>Analyzing mobile applications and website traffic</li>
                    <li>Handling inquiries and complaints</li>
                    <li>Delivering news alerts</li>
                </ul>

                <h2 className="mt-6 text-2xl font-semibold">How do we Protect your Data?</h2>
                <p className="leading-relaxed">
                    We use a variety of physical and technical measures to keep personal information safe and prevent unauthorized access, use, or disclosure of it. Electronic data and databases are stored on secure computer systems with control over who has access to information using both physical and electronic means. Our colleagues receive data protection training and are required to follow detailed security and data protection policies when handling personal information.
                </p>

                <p className="leading-relaxed">
                    While we take reasonable steps to secure personal information, we cannot guarantee its security during transmission to a website or other services, as we do not control the transmission. We use HTTPS for secure communication, represented by a lock icon in your browser, ensuring the transmission is encrypted via Transport Layer Security (TLS) with a certificate issued by a security authority.
                </p>

                <h2 className="mt-6 text-2xl font-semibold">Contacting Us</h2>
                <p className="leading-relaxed">
                    For any questions about this Privacy Policy or our use of your information, you can contact our Privacy team by submitting a request here. Alternatively, you can direct your questions to our <span className='underline text-brand-green'><a href="mailto:saba.parveen@biosymposiums.com">email</a></span>.
                </p>

                <p className="leading-relaxed">
                    We hope to resolve any privacy concerns you may have, but you always have the right to complain to a supervisory authority responsible for enforcing privacy laws in your country.
                </p>

                <h2 className="mt-6 text-2xl font-semibold">Changes to this Privacy Policy</h2>
                <p className="leading-relaxed">This Privacy Policy was last updated on (TBC).</p>
            </div>
        </>
    );

}

export default PrivacyPolicyPage