import React from 'react';
import phoneGreen from "../../assets/PhoneGreen.svg";
import emailGreen from "../../assets/EmailGreen.svg";

function ContactUs() {
    const contacts = [
        {
            contactFor: "Sponsorship Enquiry",
            name: "Saba Parveen",
            title: "Senior Partnership Manager",
            email: "saba.parveen@biosymposiums.com ",
            phone: "+91-9939289548"
        }, {
            contactFor: "Speaker Enquiry",
            name: "Kashish Jethwani",
            title: "Senior Conference Producer",
            email: "kashish.jethwani@biosymposiums.com",
            phone: "+91-9697777762"
        },

    ]
    return (
        <div className='h-full pb-10 bg-white'>
            <div className='grid text-center justify-center py-[1.827vh] px-[2.646vw] mb-[72px] '>
                <p className='mt-20 font-semibold text-48px'>You can also get in touch with our team directly</p>
            </div>
            <div className='flex justify-center pb-10 xs:gap-2 md:gap-10 xs:flex-wrap md:flex-nowrap'>
                {
                    contacts.map((contact, index) => {
                        return (
                            <div className="xs:w-[90vw] md:w-[43.122vw] md:min-w-[652px] mb-10" key={index}>
                                <div className="bg-[url('assets/GreenAngleStrip.svg')] bg-cover bg-no-repeat px-10 py-3 w-[70%]">
                                    <p className='font-medium text-white text-24px'>{contact.contactFor}</p>
                                </div>
                                <div className='bg-[#F6F6F6] p-10'>
                                    <p className='mb-2 font-semibold text-30px text-brand-green'>{contact.name}</p>
                                    <p className='text-22px text-[#828282] mb-4'>{contact.title}</p>
                                    <a href={`mailto:${contact.email}`}>
                                        <div className='flex items-center justify-start gap-2 mb-4'>
                                            <img src={emailGreen} alt="Email" />
                                            <p className='font-medium xs:text-16px md:text-24px'>{contact.email}</p>
                                        </div>
                                    </a>
                                    <a href={`tel:${contact.phone}`}>
                                        <div className='flex items-center justify-start gap-2 mb-4'>
                                            <img src={phoneGreen} alt="Phone No." />
                                            <p className='font-medium xs:text-16px md:text-24px'>{contact.phone}</p>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default ContactUs