import React from 'react';
import { Link } from 'react-router-dom';
import logo from "../assets/BioSymposiums 1.svg";
import registerMic from "../assets/register_mic.svg";

function CustomNavbar({ active, onNavigate }) {

    return (
        <div className={`sticky flex justify-between items-center py-[1.827vh] px-[2.646vw] transition-all duration-300 z-[10] ${active !== "Home" ? "bg-white top-0 pt-8" : "bg-transparent pt-4 top-3"}`}>
            <Link to="/">
                <img src={logo} alt="BioSymposiums" className='min-h-[32px] h-8 w-[144.48px]' />
            </Link>

            <Link to="/">
                <div className='items-center hidden gap-2 px-2 py-1 border border-solid cursor-pointer md:flex border-brand-red' >
                    <img src={registerMic} alt="register" className="w-6 h-6" />
                    <span className={`font-semibold ${active === "Events" ? "text-brand-red" : active !== "Home" ? "text-black" : "text-white"}`}>Register for Event</span>
                </div>
            </Link>
        </div>
    )
}

export default CustomNavbar;