import React from 'react';
import LinkedInLogo from "../../assets/LinkedInGreen.png";
import DrKumarPrabash from "../../assets/DrKumarPrabash.jpeg";
import DrMuraliRamachandra from "../../assets/DrMuraliRamachandra.jpeg";
import DrRajashreeDevarakonda from "../../assets/DrRajashreeDevarakonda.jpeg";
import DrRajashreeDevarakondaMale from "../../assets/DrRajashreeDevarakonda(Male).png"

function OurAdvisors() {
    const doctorList = [
        {
            name: "Dr. Kumar Prabash",
            company: "Tata Memorial Hospital",
            title: "Head of the Medical Oncology Department",
            linkedin: "",
            image: DrKumarPrabash
        },
        {
            name: "Dr. Murali Ramachandra",
            company: "Aurigene Discovery Technologies",
            title: "Chief Executive Officer",
            linkedin: "https://www.linkedin.com/in/murali-ramachandra-6800246/",
            image: DrMuraliRamachandra
        },
        {
            name: "Dr. Rajashree Devarakonda",
            company: "Voisin Consulting Life Sciences (VCLS)",
            title: "Chief Scientific Officer",
            linkedin: "https://www.linkedin.com/in/dr-rajashree-devarakonda-58974324/",
            image: DrRajashreeDevarakonda
        },
        {
            name: "Dr. Nagaraj Gowda",
            company: "Ichnos Glenmark Innovation",
            title: "Vice President and Head, Small Molecule Research",
            linkedin: "https://www.linkedin.com/in/nagaraj-gowda-821018b/",
            image: DrRajashreeDevarakondaMale
        },

    ]
    return (
        <div className="bg-white min-h-[75vh]">
            <div className='grid text-center justify-center py-[1.827vh] px-[2.646vw] '>
                <p className='mt-20 font-semibold text-48px'>Our notable advisors</p>
                <p className='font-medium text-16px text-[#727272]'>We’re glad to showcase our notable advisors for the event</p>
            </div>
            <div className='xs:mx-5 md:mx-[9.656vw] grid lg:grid-cols-4 gap-x-14 justify-center gap-5 mt-16 md:grid-cols-2'>
                {
                    doctorList.map((doctor, index) => {
                        return (
                            <div className='grid gap-x-5 gap-y-2 md:gap-x-12 border border-solid border-[#EAECF0] mb-10' key={index}>
                                <img src={doctor.image} alt={doctor.name} className='h-[321px] w-full' />
                                <div className='grid p-2 gap-y-2'>

                                    <p className='py-2 font-semibold text-16px'>{doctor.name}</p>
                                    <p className='text-10px font-medium text-[#5F6980]'>{doctor.title}</p>
                                    <p className='text-10px font-medium text-[#3E3E59]'>{doctor.company}</p>
                                    {
                                        doctor.linkedin ? <a href={doctor.linkedin} target="_blank" rel="noreferrer">
                                            <img src={LinkedInLogo} className='mt-4' alt="LinkedIn" />
                                        </a>
                                            :
                                            <div className='h-5 mt-4'></div>
                                    }
                                </div>
                            </div>
                        )
                    })
                }

            </div>
        </div>
    )
}

export default OurAdvisors