import React from 'react';
import LinkedInLogo from "../../assets/LinkedInGreen.png";
import AdithyaKulkarni from "../../assets/speakers/AdithyaKulkarni.png";
import DrEmanuelleVoisin from "../../assets/speakers/DrEmanuelleVoisin.png";
import JigneshPatel from "../../assets/speakers/JigneshPatel.png";
import MurthyChavali from "../../assets/speakers/MurthyChavali.png";
import NileshDhamne from "../../assets/speakers/NileshDhamne.png";
import PeterMarks from "../../assets/speakers/PeterMarks.png";
import PrashantKumar from "../../assets/speakers/PrashantKumar.png";
import RahulPurwar from "../../assets/speakers/RahulPurwar.png";
import RamanaKumarK from "../../assets/speakers/RamanaKumarK.png";
// import SanishDavis from "../../assets/speakers/SanishDavis.png";
import SatishShankaran from "../../assets/speakers/SatishShankaran.png";
import SridharanRajagopal from "../../assets/speakers/SridharanRajagopal.png";
import SwagatamRay from "../../assets/speakers/SwagatamRay.png";
import VikramMatthews from "../../assets/speakers/VikramMatthews.png";
import VishalUSRao from "../../assets/speakers/VishalUSRao.png";
import NitinDamle from "../../assets/speakers/NitinDamle.png";

function OurSpeakers() {
    const speakerList = [
        {
            name: "Rahul Purwar",
            company: "ImmunoACT",
            title: "Chief Executive Officer",
            linkedin: "https://www.linkedin.com/in/rahul-purwar-3187608a/",
            image: RahulPurwar
        },
        {
            name: "Nitin Damle",
            company: "Sun Pharma Advanced Research Company Ltd.",
            title: "Executive Vice President and Cheif Innovation Officer",
            linkedin: "https://www.linkedin.com/in/nitin-damle-b8a4048/",
            image: NitinDamle
        },
        {
            name: "Dr. Vikram Mathews",
            company: "Christian Medical College Vellore",
            title: "Director",
            linkedin: "https://www.linkedin.com/in/vikram-mathews-6134012a8/",
            image: VikramMatthews
        },
        {
            name: "Peter Marks",
            company: "United States Food and Drug Administration",
            title: "Director",
            linkedin: "",
            image: PeterMarks
        },
        {
            name: "Aditya Kulkarni",
            company: "Avammune Therapeutics",
            title: "Founder and Chief Scientific Officer",
            linkedin: "https://www.linkedin.com/in/adikulkarnis",
            image: AdithyaKulkarni
        },
        {
            name: "Murthy Chavali",
            company: "Satya Pharma Innovations Pvt. Ltd.",
            title: "Chief Executive Officer",
            linkedin: "",
            image: MurthyChavali
        },
        {
            name: "Swagatam Ray",
            company: "Intas Pharmaceuticals",
            title: "Head, Cell Therapy and Discovery CGT",
            linkedin: "https://www.linkedin.com/in/swagatam-ray-phd-30206638/",
            image: SwagatamRay
        },
        {
            name: "Dr. Emmanuelle Voisin, Ph.D.",
            company: "Voisin Consulting Life Sciences",
            title: "Founder & CEO",
            linkedin: "https://www.linkedin.com/in/emmanuelle-voisin-phd-3490606/",
            image: DrEmanuelleVoisin
        },
        {
            name: "Ramana Kumar. K",
            company: "Cipla",
            title: "Director: Head Regulatory Affairs API",
            linkedin: "https://www.linkedin.com/in/ramana-kumar-k-a4614214/",
            image: RamanaKumarK
        },
        {
            name: "Sridharan Rajagopal",
            company: "Jubilant Therapeutics Inc",
            title: "Vice President - Head of Medicinal Chemistry",
            linkedin: "https://www.linkedin.com/in/sridharan-rajagopal-5188886/",
            image: SridharanRajagopal
        },
        // {
        //     name: "Sanish Davis",
        //     company: "The Janssen Pharmaceutical Companies of Johnson & Johnson",
        //     title: "R & D Director",
        //     linkedin: "https://www.linkedin.com/in/dr-sanish-davis-md-dm-fcp-9971714/",
        //     image: SanishDavis
        // },
        {
            name: "Dr. Nilesh Dhamne",
            company: "Kolhapur Cancer Centre",
            title: "Principal Investigator",
            linkedin: "https://www.linkedin.com/in/dr-nilesh-dhamne-822083b/",
            image: NileshDhamne
        },
        {
            name: "Dr. Jignesh Patel",
            company: "Parul University",
            title: "Professor, PhD Guide ande Head: Clinical Research",
            linkedin: "https://www.linkedin.com/in/dr-jignesh-patel-5a4b8419/",
            image: JigneshPatel
        },
        {
            name: "Satish Sankaran",
            company: "Farcast Biosciences Pvt. Ltd",
            title: "Chief Scientific Officer",
            linkedin: "https://www.linkedin.com/in/satish-sankaran-80840118/",
            image: SatishShankaran
        },
        {
            name: "Prashant Kumar",
            company: "IIT-Kanpur, Karkinos Foundation and Karkinos Pvt Limited",
            title: "Adjunct Professor, Research Director & CSO",
            linkedin: "https://www.linkedin.com/in/prashant-kumar-2394231a/",
            image: PrashantKumar
        },
        {
            name: "Dr, (Inv) Prof Vishal U S Rao",
            company: "HealthCare Global",
            title: "Dean",
            linkedin: "https://www.linkedin.com/in/drvishalrao/",
            image: VishalUSRao
        },

    ]
    return (
        <div className="bg-white min-h-[75vh]">
            <div className='grid text-center justify-center py-[1.827vh] px-[2.646vw] '>
                <p className='mt-20 font-semibold text-48px'>Our Valuable Speakers</p>
                <p className='font-medium text-16px text-[#727272]'>We’re glad to showcase our top notch speakers for the event</p>
            </div>
            <div className='xs:mx-5 md:mx-[9.656vw] grid lg:grid-cols-4 gap-x-14 justify-center gap-5 mt-16 md:grid-cols-2'>
                {
                    speakerList.map((speaker, index) => {
                        return (
                            <div className='grid gap-x-5 gap-y-2 md:gap-x-12 border border-solid border-[#EAECF0]' key={index}>
                                <img src={speaker.image} alt={speaker.name} className='h-[321px] w-full' />
                                <div className='grid p-2 gap-y-2'>

                                    <p className='font-semibold text-18px'>{speaker.name}</p>
                                    <p className='text-14px font-medium text-[#5F6980]'>{speaker.title}</p>
                                    <p className='text-14px font-medium text-[#3E3E59]'>{speaker.company}</p>
                                    {
                                        speaker.linkedin ?

                                            <a href={speaker.linkedin} target="_blank" rel="noreferrer">
                                                <img src={LinkedInLogo} className='mt-4' alt="LinkedIn" />
                                            </a>
                                            :
                                            <div className='h-5 mt-4'></div>
                                    }
                                </div>
                            </div>
                        )
                    })
                }

            </div>
        </div>
    )
}

export default OurSpeakers