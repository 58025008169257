import React from 'react';
import sponsorFeatures from "../../assets/SponsorFeatures.svg";
import EmailForm from '../reusables/EmailForm';

function SponsorshipInfo() {
    return (
        <div className='grid justify-center bg-white '>
            <div className='grid mx-auto w-[80%] md:w-[90%]'>
                <div className='grid text-center justify-center py-[1.827vh] '>
                    <p className='mt-20 font-semibold text-48px'>Sponsorship enquiries</p>
                    <p className='font-medium text-16px text-[#727272] text-center'>You’re welcomed to connect with us to discuss the sponsorship enquiries</p>
                </div>
                <p className='font-medium text-16px text-[#727272] mt-6'>Explore the  potential benefits for your brand with <span className='text-brand-green'>#</span><span className='text-black'>CIOIndia24 -</span></p>
                <div className='grid gap-10 md:gap-[60px] md:grid-cols-2'>
                    <div className='grid justify-between mt-16'>
                        {/* <p className='font-medium text-16px text-[#727272]'>Our diverse range of sponsorship opportunities enables you to showcase your products and services to targeted audiences, highlight your innovations, lead industry discussions, and engage with industry leaders.</p> */}
                        <img src={sponsorFeatures} alt="1: Virtual Event Opportunities, 2:Digital Marketing & Social Media, 3: Onsite Branding, 4: Education & Networking, 5:Partnership & Recognition" className='mx-auto mb-20' />
                    </div>
                    <div className='mt-16'>
                        <EmailForm extended={true} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SponsorshipInfo