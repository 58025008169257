import React from 'react';
import Card from '../reusables/Card';
import networking from "../../assets/networking.svg"
import collaborations from "../../assets/collaborations.svg";
import conferences from "../../assets/conferences.svg";
import exhibitions from "../../assets/exhibitions.svg";

function Home() {
    const desc = [{
        title: "Conferences",
        description: "Regardless of your area of interest, we provide tailored content, networking, and partnerships for all interests",
        image: conferences
    },
    {
        title: "Exhibitions",
        description: "Discover the Newest Breakthroughs: Explore the Upcoming Frontiers of Biotech and Pharma at our Exhibition",
        image: exhibitions
    },
    {
        title: "Networking",
        description: "Connect, Co-operate, Catalyze: Unlocking the Potential of Networking in Biotech and Pharma",
        image: networking
    },
    {
        title: "Collaborations",
        description: "Partnering for Progress: Fostering Innovation in Biotech and Pharma through Strategic Collaboration",
        image: collaborations
    }]
    return (
        <div className='grid min-h-[80vh]'>
            <div className='z-[1] xs:mt-60 xs:mb-10 md:mb-0 md:mt-0 flex flex-col justify-center items-center'>
                <p className='xs:mb-14 md:mb-0 font-semibold min-text-[25px] text-[3.704vmax] text-white text-center'>Connect<span className="text-brand-red">. </span>Collaborate<span className="text-brand-red">. </span>Innovate<span className="text-brand-red">.</span></p>
                <p className='text-white font-medium text-[20px] text-center'>Bringing together the pharmaceutical and biotech industries to connect communities globally.</p>
            </div>
            <div className='items-end flex xs:flex-wrap sm:flex-wrap md:flex-nowrap lg:flex-nowrap xl:flex-nowrap 2xl:flex-nowrap gap-6 justify-center'>
                {desc.map((item, index) => {
                    return <Card key={index} title={item.title} description={item.description} image={item.image} />
                })}
            </div>
        </div>
    )
}

export default Home