import CIOEventPage from "./components/CIOEventPage";
import HomePage from "./components/HomePage";
import { Routes, Route, BrowserRouter } from "react-router-dom"
import RegistrationForm from "./components/reusables/RegistrationForm";
import CancellationPolicyPage from "./components/CancellationPolicyPage";
import PrivacyPolicyPage from "./components/PrivacyPolicyPage";
function App() {

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/cioindia24" element={<CIOEventPage />} />
        <Route path="/wip/reg-under-construction" element={<RegistrationForm />} />
        <Route path="/cancellation-policy" element={<CancellationPolicyPage />} />
        <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
